import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTheMask from "vue-the-mask";
import Toast from "vue-toastification";
import vClickOutside from "click-outside-vue3";

import "vue-toastification/dist/index.css";
import globalMixin from "./assets/js/mixin.js";

const app = createApp(App).use(store).use(router);

app.use(VueTheMask);
const options = {
  position: "bottom-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  icon: false,
  rtl: false,
  toastClassName: "my-custom-toast-class",
};
app.use(Toast, options);
app.mixin(globalMixin);
app.use(vClickOutside);

router.isReady().then(() => app.mount("#app"));
