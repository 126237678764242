import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/auth",
    name: "AuthPage",
    component: () => import("@/views/Auth/AuthPage.vue"),
    children: [
      {
        path: "login",
        name: "LoginPage",
        component: () => import("@/views/Auth/Sub/LoginPage.vue"),
      },
      {
        // Not used in phase 1.
        path: "register",
        name: "RegisterPage",
        component: () => import("@/views/Auth/Sub/RegisterPage.vue"),
      },
    ],
  },
  {
    path: "/form",
    name: "FormPage",
    component: () => import("@/views/FormPage.vue"),
    meta: {
      hasNav: true,
      hasBottomNav: false,
      hasPatientInfo: false,
      hasExportPdf: false,
      hasLogout: true,
      hasDarkTheme: false,
      isAuth: true,
    },
  },
  {
    path: "/chart",
    name: "ChartPage",
    component: () => import("@/views/ChartPage.vue"),
    meta: {
      hasNav: true,
      hasBottomNav: true,
      hasPatientInfo: true,
      hasExportPdf: true,
      hasLogout: true,
      hasDarkTheme: true,
      isAuth: true,
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/PageNotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (to.meta?.isAuth) {
    // When backend is done > Change to [sid]
    const isValidSes = sessionStorage.emuSid;
    console.log(`🔒 Auth checkup [${Boolean(isValidSes)}]`);
    if (!isValidSes) {
      return { path: "/auth/login" };
      // return { name: "Loginpage" };
    }
  } else if (to.path === "/") {
    console.log("💨 Redirect to login");
    return { path: "/auth/login" };
  }
  return true;
});

export default router;
