<template>
  <div class="blood-presure">
    <div class="title" :style="{ color: isDarkTheme ? '#fff' : '#2B2B2E' }">
      {{ title }}
    </div>
    <!-- <button @click.stop="visible = !visible">VISIBLE</button> -->
    <div class="content" v-if="visible">
      <div class="legend" :class="{ light: !isDarkTheme }">
        <div class="legend-vertical">
          <span
            class="legend-vertical-label"
            :class="{ cancelRotate: !isDarkTheme }"
            >S<br />I<br />S<br />T<br />O<br />L<br />N<br />I</span
          >
          <span>80</span>
          <span>90</span>
          <span>100</span>
          <span>110</span>
          <span>120</span>
          <span>130</span>
          <span>140</span>
          <span>150</span>
          <span>160</span>
          <span>170</span>
          <span>180</span>
          <span>190</span>
          <span>200</span>
          <span>210</span>
          <span>220</span>
        </div>
        <div class="legend-horizontal">
          <span class="legend-horizontal-label">DIJASTOLNI</span>
          <span>50</span>
          <span>60</span>
          <span>70</span>
          <span>80</span>
          <span>90</span>
          <span>100</span>
          <span>110</span>
          <span>120</span>
          <span>130</span>
          <span>140</span>
          <span>150</span>
        </div>
      </div>
      <div class="cubes">
        <Transition name="scalex" appear>
          <div
            class="result-horizontal"
            :class="{ static: !isAnimated }"
            :style="computeBPPos(systolicBP, diastolicBP, true)"
          ></div>
        </Transition>
        <Transition name="scaley" appear>
          <div
            class="result-vertical"
            :class="{ static: !isAnimated }"
            :style="computeBPPos(systolicBP, diastolicBP, false)"
          ></div>
        </Transition>
        <Transition name="pulse" appear>
          <div
            class="result-indicator"
            :class="[{ pulse: indicatorAnimated }, { static: !isAnimated }, { translatedY: isOutOfRangeSystolic }, { translatedX: isOutOfRangeDiastolic }]"
            :style="computeBPPos(systolicBP, diastolicBP, false)"
          ></div>
        </Transition>
        <TransitionGroup name="translate" appear>
          <div
            v-for="(background, bgIndex) of backgrounds"
            :key="bgIndex"
            :class="[
              'background-' + backgrounds[bgIndex],
              { static: !isAnimated },
            ]"
          ></div>
        </TransitionGroup>
        <template v-for="(section, secIndex) of expandedCubes" :key="secIndex">
          <template v-for="(cubeEl, cubeIndex) of section[1]" :key="cubeIndex">
            <TransitionGroup name="fade" appear>
              <span
                class="cube"
                :class="{ light: !isDarkTheme }"
                :style="computeStyle(secIndex, section[0], cubeIndex)"
                :key="'' + secIndex + section[1]"
              ></span>
            </TransitionGroup>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDarkTheme: {
      type: Boolean,
      default: true,
    },
    isAnimated: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Krvni pritisak",
    },
    systolicBP: {},
    diastolicBP: {},
  },
  data() {
    return {
      visible: true,
      cubeData: [
        [5, [4, 4, 4, 4, 4, 4, 4, 4, 4, 4]],
        [1, [3, 3, 3, 3, 3, 3, 3, 4, 4, 4]],
        [1, [2, 2, 2, 2, 2, 2, 3, 4, 4, 4]],
        [7, [1, 1, 1, 1, 1, 2, 3, 4, 4, 4]],
      ],
      pulsing: false,
      isOutOfRangeSystolic: false,
      isOutOfRangeDiastolic: false,
    };
  },
  computed: {
    expandedCubes() {
      return this.cubeData.reduce((acc, curr) => {
        for (let index = 0; index < curr[0]; index++) {
          acc.push([index, curr[1]]);
        }
        return acc;
      }, []);
    },
    backgrounds() {
      return [1, 2, 3, 4];
    },
    indicatorAnimated() {
      return this.pulsing && this.isAnimated;
    },
  },
  methods: {
    computeStyle(fi, ri, se) {
      // Delay first two rows independently
      // if (fi === 3 && ri === 7) {
      //   return 10;
      // } else if (fi === 3 && ri === 6) {
      //   return 20;
      // }

      // // Offset computed rows
      // const compRi = this.cubeData
      //   .slice(fi + 1)
      //   .reduce((acc, curr) => acc + curr[0], 0);
      // console.warn(compRi);

      // const num = (4 / (fi + 1)) * (5 / (ri + compRi + 1)) * (se + 1) * 50;
      // const msNum = `${num}ms`;
      // console.warn(num);
      // return {
      //   transitionDelay: msNum,
      // };
      return {};
    },
    computeBPPos(systolicBP, diastolicBP, horizontal) {
      let systolicVal, diastolicVal, systolicPos, diastolicPos;

      if (
        isNaN(systolicBP) ||
        systolicBP < 80 ||
        systolicBP > 220
      ) {
        systolicVal = 518;
        this.isOutOfRangeSystolic = true;
      } else {
        if (horizontal) {
          systolicVal = (systolicBP - 80) * 3.7;
        } else {
          systolicVal = (systolicBP - 80) * 3.7 - 15;
        }
      }

      if (
        isNaN(diastolicBP) ||
        diastolicBP < 50 ||
        diastolicBP > 150
      ) {
        diastolicVal = 370;
        this.isOutOfRangeDiastolic = true;
      } else {
        if (horizontal) {
          diastolicVal = (diastolicBP - 50) * 3.7 - 15;
        } else {
          diastolicVal = (diastolicBP - 50) * 3.7;
        }
      }
      
      systolicPos = `${systolicVal}px`;
      diastolicPos = `${diastolicVal}px`;
      return {
        height: systolicPos,
        width: diastolicPos,
        bottom: systolicPos,
        left: diastolicPos,
      };
    },
  },
  created() {
    setInterval(() => {
      this.pulsing = !this.pulsing;
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.blood-presure {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;
  // overflow: hidden;

  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    text-align: left;

    color: #ffffff;
    margin-bottom: 25px;
  }

  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
  }

  .content {
    // height: 100%;
    display: flex;
    position: relative;

    & .legend {
      position: absolute;
      top: 0;
      right: 0;
      left: -50px;
      bottom: -50px;
      color: #ffffff;

      &.light {
        color: #98999d;

        & .legend-vertical-label,
        & .legend-horizontal-label {
          color: #7f8084;
        }
      }

      & span {
        font-size: 10px;
      }

      & .legend-vertical {
        position: absolute;
        inset: -20px 380px 30px 0;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: flex-end;

        & .legend-vertical-label {
          position: absolute;
          left: -15px;
          bottom: 40px;
          transform: rotate(270deg);
          text-align: center;

          & br {
            display: none;
          }

          &.cancelRotate {
            transform: none;
            left: -5px;
            & br {
              display: initial;
            }
          }
        }
      }

      & .legend-horizontal {
        position: absolute;
        inset: 528px -19px 0 30px;
        display: flex;
        justify-content: space-around;

        & .legend-horizontal-label {
          position: absolute;
          left: 10px;
          bottom: 5px;
        }

        & span {
          width: 18px;
          text-align: center;
        }
      }
      & .legend-vertical-label,
      & .legend-horizontal-label {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .cubes {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      grid-template-rows: repeat(14, 1fr);
      // gap: 2px;
      color: white;
      height: 100%;
      position: relative;
      overflow: hidden;

      & .background-1,
      & .background-2,
      & .background-3,
      & .background-4 {
        position: absolute;
        left: 0;
        bottom: 0;
        // transform: translatey(100%);
      }

      & .background-1 {
        inset: 0;
        background-color: #f35467;
        transition: 2.1s;
      }

      & .background-2 {
        right: calc(5 * $square-side);
        top: calc(5 * $square-side);
        background-color: #f6b172;
        transition: 2.4s;
      }

      & .background-3 {
        top: calc(6 * $square-side);
        right: calc(6 * $square-side);
        background-color: #5aeabd;
        transition: 2.7s;
      }

      & .background-4 {
        top: calc(7 * $square-side);
        right: calc(7 * $square-side);
        background-color: #38d49c;
      }

      .cube {
        min-width: $square-side;
        min-height: $square-side;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: background-color 0.3s ease;
        border: 1px solid #23242b;
        position: relative;

        &.light {
          border-color: #ffffff;
        }

        // &:hover {
        //   background-color: rgba(255, 255, 255, 0.7);
        // }

        // &.color1 {
        //   $color: #38d49c;
        //   background: $color;

        //   // &:hover {
        //   //   background: lighten($color, 10);
        //   // }
        // }
        // &.color2 {
        //   $color: #5aeabd;
        //   background: $color;

        //   // &:hover {
        //   //   background: lighten($color, 10);
        //   // }
        // }
        // &.color3 {
        //   $color: #f6b172;
        //   background: $color;

        //   // &:hover {
        //   //   background: lighten($color, 10);
        //   // }
        // }
        // &.color4 {
        //   $color: #f35467;
        //   background: $color;

        //   // &:hover {
        //   //   background: lighten($color, 10);
        //   // }
        // }
      }
      & .result-horizontal,
      & .result-vertical {
        position: absolute;
        left: 1px !important;
        bottom: 1px !important;
        z-index: 2;
        pointer-events: none;
      }

      & .result-horizontal {
        border-top: 2px dashed white;
        transition: 3s;
        transform-origin: left;
        max-width: calc(100% - 15px);
      }

      & .result-vertical {
        border-right: 2px dashed white;
        transform-origin: bottom;
        max-height: calc(100% - 15px);
      }

      & .result-indicator {
        position: absolute;
        height: 30px !important;
        width: 30px !important;
        border: 2px solid white;
        border-radius: 100%;
        z-index: 2;
        // transform: scale(1) translateX(-15px);
        margin-left: -15px;
        pointer-events: none;
        bottom: -15px;
        transform-origin: center;
        background-color: #ffffff2a;
        transition: transform 0.5s;

        &.static {
          background-color: transparent;
        }

        &.pulse {
          transform: scale(1.3);
        }

        &.translatedY {
          margin-bottom: -15px;
        }
        &.translatedX {
          margin-left: -15px;
        }
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: all 3s ease;
  }

  .fade-enter,
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(200px);
  }

  .translate-enter-active,
  .translate-leave-active {
    transition: all 3s ease;
  }

  .translate-enter,
  .translate-enter-from,
  .translate-leave-to {
    transform: translateY(100%);
  }
}

.scaley-enter-active,
.scaley-leave-active {
  transition: all 3s ease;
}

.scaley-enter,
.scaley-enter-from,
.scaley-leave-to {
  transform: scaleY(0);
}

.scalex-enter-active,
.scalex-leave-active {
  transition: all 3s ease;
}

.scalex-enter,
.scalex-enter-from,
.scalex-leave-to {
  transform: scaleX(0);
}

.pulse-enter-active,
.pulse-leave-active {
  transition: all 3s ease !important;
}

.pulse-enter,
.pulse-enter-from,
.pulse-leave-to {
  opacity: 0;
  transform: scale(0);
}
.static {
  transition: all 0s !important;
}
</style>
