<template>
  <div :class="{ 'ring-full-chart': true, modal: isModal }">
    <div
      class="title"
      :style="{ color: this.isDarkTheme ? '#fff' : '#2B2B2E' }"
    >
      {{ this.title }}
    </div>
    <div class="wrapp-ring">
      <div ref="ring" class="ring" :id="id"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import { graphic } from "echarts";
export default {
  name: "RygRing",
  props: {
    setColor: { type: Array },
    setValue: {},
    title: {},
    id: {},
    max: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  created() {
    this.valueThis = parseFloat(String(this.setValue).slice(0, 3));
  },
  data() {
    return {
      valueThis: 0,
      myChart: null,
      green: ["#34D67E", "#34D67E", "#22C59D"],
      orange: ["#22C59D", "#F39D4E", "#F34E4E"],
      brightRed: ["#F34E4E", "#F26A58", "#E9415F"],
      red: ["#E9415F", "#F34E4E", "#E33955"],
      darkRed: ["#E33955", "#E33955", "#9e2828"],
    };
  },

  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setChart() {
      // console.log(graphic);
      var chartDom = this.$refs.ring;
      this.myChart = echarts.init(chartDom);
      var option;

      const gaugeData = [
        {
          value: this.valueThis,
          itemStyle: {
            // shadowOffsetY: this.isDarkTheme ? 1 : 0,

            color: "transparent",
          },

          name: false,
          title: {
            offsetCenter: ["0%", "-25%"],
            color: "#BCBDBF",
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "0%"],
            // color: "#fff",
            fontSize: this.isModal ? 46 : 31,
            color: this.isDarkTheme ? "#fff" : "#2B2B2E",
            borderColor: "none",
            borderRadius: 20,
          },
        },
      ];
      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        textStyle: {
          fontFamily: "Archivo",
        },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            // endAngle: -250,
            // startAngle: 135,
            // endAngle: -225,
            min: 0,
            max: this.max,
            pointer: {
              show: false,
            },
            progress: {
              show: false,
              overlap: false,

              roundCap: true,
              //   clip: true,
            },
            axisLine: {
              lineStyle: {
                width: this.isModal ? 26 : 13,
                // width: 50,
                color: this.isDarkTheme ? [[1, "#14161D"]] : [[1, "#EBEBEB"]],
                // color: [[1, this.setColor]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 0,
            },
            axisTick: {
              show: false,
              distance: 0,
              length: 0,
              lineStyle: {
                color: this.isDarkTheme ? "#fff" : "#2B2B2E",
                width: 0,
              },
            },
            axisLabel: {
              show: false,
              distance: 0,
            },
            data: gaugeData,
            title: {
              fontSize: this.isModal ? 22 : 12,
            },
            detail: {
              //   width: 50,
              //   height: 14,
              // fontSize: 31,
              // // color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              // color: "red",
              // borderColor: "none",
              // borderRadius: 20, // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "10%"],
              //   },
              // }, fontSize: 31,
              // color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              color: "red",
              borderColor: "none",

              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
              borderWidth: 0,
              formatter: "{value}",
            },
          },
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            // endAngle: -250,
            // startAngle: 135,
            // endAngle: -225,
            min: 0,
            max: this.max,
            pointer: {
              //   show: false,
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: this.isModal ? "23%" : "24%",
              width: this.isModal ? 6 : 4,

              offsetCenter: this.isModal ? [0, "-77%"] : [0, "-76%"],
              itemStyle: {
                color: "#ffffff", // "#ffffffdc"
                shadowColor: "rgba(255, 255, 255)",
                shadowBlur: this.isModal ? 6 : 4,
              },
            },
            progress: {
              show: true,
              overlap: false,
              color: "transparent",
              roundCap: true,
              //   clip: true,
            },
            axisLine: {
              lineStyle: {
                width: this.isModal ? 26 : 13,
                // width: 50,
                // color: this.isDarkTheme ? [[1, "#14161D"]] : [[1, "#EBEBEB"]],
                // color: [
                //   [0.31, this.green[0]],
                //   [0.448, this.orange[1]],
                //   [0.68, this.brightRed[1]],
                //   [0.93, this.red[1]],
                //   [1, this.darkRed[2]],
                // ],
                color: this.setColor,
                roundCap: true,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 0,
            },
            axisTick: {
              show: false,
              distance: 0,
              length: 0,
              lineStyle: {
                color: this.isDarkTheme ? "#fff" : "#2B2B2E",
                width: 0,
              },
            },
            axisLabel: {
              show: false,
              distance: 0,
            },
            data: gaugeData,
            title: {
              show: false,
              fontSize: this.isModal ? 22 : 12,
            },
            detail: {
              //   width: 50,
              //   height: 14,
              // fontSize: 31,
              // // color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              // color: "red",
              // borderColor: "none",
              // borderRadius: 20, // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "10%"],
              //   },
              // }, fontSize: 31,
              // color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              color: "red",
              borderColor: "none",

              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
              borderWidth: 0,
              formatter: "{value}",
            },
          },
        ],
      };

      //   setInterval(() => {
      //     //   gaugeData[0].value = +(Math.random() * 100).toFixed(2);
      //     //   gaugeData[1].value = +(Math.random() * 100).toFixed(2);
      //     //   gaugeData[2].value = +(Math.random() * 100).toFixed(2);
      //     //   myChart.setOption({
      //     //     series: [
      //     //       {
      //     //         data: gaugeData,
      //     //         pointer: {
      //     //           show: false,
      //     //         },
      //     //       },
      //     //     ],
      //     //   });
      //     this.myChart.setOption(option);
      //   }, 2000);

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.ring-full-chart {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    /* identical to box height */

    // text-align: center;
    margin-left: 20px;
    color: #ffffff;
    // color: #e9cd85b0;

    // color: #34D67E;
    // color: #22C59D;

    // color: #F39D4E;
    // color: #F34E4E;

    // color: #F26A58;
    // color: #E9415F;

    // color: #F34E4E;
    // color: #E33955;

    // color: #E33955;
    // color: #9e2828;
  }
  .wrapp-ring {
    //   width: 182px;
    //   height: 182px;
    position: relative;
    width: 140px;
    height: 140px;
    .ring {
      width: 100%;
      height: 100%;
    }
  }
}
.ring-full-chart.modal {
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    /* identical to box height */

    text-align: center;
    margin-left: 0px;
    color: #ffffff;
  }
  .wrapp-ring {
    //   width: 182px;
    //   height: 182px;
    position: relative;
    width: 300px;
    height: 300px;
    .ring {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
