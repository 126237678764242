<template>
  <div class="pdf-element2" ref="pdf-wrapper2">
    <modern-modal
      :config="{ title: 'Pregled PDF', canResize: true }"
      :form-style="{ width: '100%', height: '100%' }"
      v-if="isModalVisible"
      @close-modal="isModalVisible = false"
    >
      <template #content>
        <pdf-preview
          :imagePreview="imagePreview"
          @close-modal="isModalVisible = false"
          @print-preview="makePDF"
        ></pdf-preview>
      </template>
    </modern-modal>
    <button class="red" @click="onClickPdfExport">
      <img src="@/assets/image/pdf.svg" alt="" />
      <span>Eksportovanje u PDF</span>
    </button>
    <div id="pdf-test">
      <!-- <testTest :isDarkTheme="false" :animationDuration="100"> </testTest> -->
    </div>
  </div>
</template>

<script>
import { nDate } from "../../assets/js/dateHelper.ts";
import { mapGetters } from "vuex";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
// import testPdf from "@/views/TestPdf.vue";
// import testTest from "@/components/Pdf/PdfPreview.vue";
import "@/assets/fonts/Archivo-ExtraLight-normal.js";
export default {
  data() {
    return {
      isModalVisible: false,
      imagePreview: "",
      something: "hello",
      reportDate: null,
    };
  },
  components: {
    // testPdf: testPdf,
    // testTest: testTest,
  },
  computed: {
    ...mapGetters(["patientData"]),
  },
  created() {
    this.reportDate = this.getReportDate();
  },
  methods: {
    onClickPdfExport() {
      this.isModalVisible = true;
    },
    makePDF() {
      //Imported font as Base64, needs refactoring to optimize component size
      window.html2canvas = html2canvas;
      var doc = new jsPDF("l", "px", [1550, 1130]);
      // doc.addFileToVFS("Archivo.ttf", font);
      doc.addFont("Archivo-ExtraLight-normal.ttf", "Archivo", "normal");
      // doc.setFont("Archivo-Regular");
      // const element = "#pdf-test";
      // const oldTestEl = ".pdf-element2";
      const element = "#testPdfPrint";
      const patientDataPDF = this.patientData;
      const reportDate = this.reportDate.replaceAll(".", "/");
      doc.html(document.querySelector(element), {
        callback: function (pdf) {
          // this.imagePreview = pdf.output("bloburl");

          pdf.save(
            patientDataPDF.firstName +
              " " +
              patientDataPDF.lastName +
              " " +
              reportDate
          );
          console.log(patientDataPDF);
        },
        x: 10,
        y: 10,
      });
      setTimeout(() => {
        this.isModalVisible = false;
      }, 1000);
      console.log(this.reportDate);
      console.log(typeof this.reportDate);
      console.log(
        patientDataPDF.firstName +
          " " +
          patientDataPDF.lastName +
          " " +
          reportDate
      );
    },
    getReportDate() {
      const date = new nDate();
      return date.formatDate("DD. MMM YYYY.");
    },
  },
};
</script>

<style lang="scss" scoped>
.showHide {
  display: none;
}
.pdf-element2 {
  display: flex;
  gap: 20px;
}
.red {
  width: 171px;
  height: 44px;
  background: $export-button;
  border-radius: 121px;
}
// #pdf-test {
  // position: absolute;
  // left: 400px;
  // top: 50px;
  // display: none;
  // visibility: hidden;
  // bottom: 150px;
// }
</style>
