<template>
  <div :class="{ 'ring-full-chart': true, modal: isModal }" :id="id">
    <div ref="ring" class="ring"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import { graphic } from "echarts";
export default {
  name: "FullRing",
  props: {
    setColor: { type: Array },
    setValue: {},
    title: {},
    unit: {},
    id: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  data() {
    return {
      myChart: null,
      setValueThis: 0,
    };
  },
  created() {
    this.setValueThis = parseFloat(
      this.unit.length > 0
        ? String(this.setValue).slice(0, 4)
        : String(this.setValue).slice(0, 7)
    );
  },
  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setChart() {
      var chartDom = this.$refs.ring;
      this.myChart = echarts.init(chartDom);
      var option;

      const gaugeData = [
        {
          value: this.setValueThis,
          itemStyle: {
            // shadowOffsetY: this.isDarkTheme ? 4 : 0,
            // shadowOffsetX: this.isDarkTheme ? 3 : 3,
            //   color: this.setColor
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: this.setColor[0],
              },
              {
                offset: 1,
                color: this.setColor[1],
              },
            ]),
          },
          name: this.title,
          title: {
            offsetCenter: ["0%", "-25%"],
            color: this.isDarkTheme ? "#BCBDBF" : "#7A7E89",
          },
          detail: {
            valueAnimation: true,
            offsetCenter: ["0%", "10%"],
            color: this.isDarkTheme ? "#fff" : "#2B2B2E",
          },
        },
        // {
        //   name: this.id !== "bazalni" ? "" : this.unit,
        //   title: {
        //     offsetCenter: ["0%", "0%"],
        //   },
        //   detail: {
        //     valueAnimation: true,
        //     offsetCenter: ["0%", "10%"],
        //   },
        // },
        // {
        //   value: 60,
        //   name: "Commonly",
        //   title: {
        //     offsetCenter: ["0%", "30%"],
        //   },
        //   detail: {
        //     valueAnimation: true,
        //     offsetCenter: ["0%", "40%"],
        //   },
        // },
      ];
      option = {
        animationDuration: this.animationDuration,

        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        textStyle: {
          fontFamily: "Archivo",
        },
        series: [
          {
            type: "gauge",
            startAngle: 90,
            endAngle: -270,
            min: 0,
            max: this.setValueThis,
            splitNumber: 6,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,

              //   roundCap: true,
              //   clip: true,
              itemStyle: {
                // borderWidth: 1,
                // borderColor: "#464646",
              },
            },
            axisLine: {
              lineStyle: {
                width: this.isModal ? 21 : 11,
                color: this.isDarkTheme ? [[1, "#14161D"]] : [[1, "#EBEBEB"]],
                // color: [[1, this.setColor]],
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: true,
              distance: this.isModal ? 11 : 7,
              length: 1,
              lineStyle: {
                color: this.isDarkTheme ? "#A6A6A6" : "#000",
                width: this.isModal ? 4 : 2,
              },
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: gaugeData,
            title: {
              fontSize: this.isModal
                ? 20
                : this.title === "Bazalni metabolizam"
                ? 11
                : 12, // 12
            },
            detail: {
              //   width: 50,
              //   height: 14,
              fontSize: this.isModal
                ? 44
                : this.title === "Bazalni metabolizam"
                ? 26
                : 30,
              color: this.setColor,
              borderColor: "none",
              borderRadius: 20, // {
              //   value: 40,
              //   name: "Good",
              //   title: {
              //     offsetCenter: ["0%", "0%"],
              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "10%"],
              //   },
              // },
              // {
              //   value: 60,
              //   name: "Commonly",
              //   title: {
              //     offsetCenter: ["0%", "30%"],
              //   },
              //   detail: {
              //     valueAnimation: true,
              //     offsetCenter: ["0%", "40%"],
              //   },
              // },
              borderWidth: 0,
              formatter:
                this.id !== "bazalni" ? "{value} " + this.unit : "{value}",
              //   : "{value}\n" + this.unit,
            },
          },
        ],
      };
      //   setInterval(function () {
      //     gaugeData[0].value = +(Math.random() * 100).toFixed(2);
      //     gaugeData[1].value = +(Math.random() * 100).toFixed(2);
      //     gaugeData[2].value = +(Math.random() * 100).toFixed(2);
      //     myChart.setOption({
      //       series: [
      //         {
      //           data: gaugeData,
      //           pointer: {
      //             show: false,
      //           },
      //         },
      //       ],
      //     });
      //   }, 2000);

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.ring-full-chart {
  //   width: 182px;
  //   height: 182px;
  position: relative;
  display: flex;
  width: 190px;
  height: 190px;
  overflow: hidden;
  .ring {
    position: absolute;
    top: -10px;
    left: -20px;
    width: 220px;
    height: 220px;
  }
}
.ring-full-chart.modal {
  //   width: 182px;
  //   height: 182px;
  position: initial;
  width: 400px;
  height: 400px;

  .ring {
    position: initial;
    width: 400px;
    height: 400px;
  }
}
</style>
