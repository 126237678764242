<template>
  <div :class="{ fat: true, modal: isModal }">
    <div ref="mainFat" id="mainFat"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { graphic } from "echarts";
export default {
  name: "PercentageFat",
  props: {
    setColor: {},
    isDarkTheme: {},
    age: {},
    fat: {},
    gender: {}, // male   female
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  created() {
    this.fatThis = parseFloat(String(this.fat).slice(0, 3));
  },
  data() {
    return {
      myChart: null,
      fatThis: 0,
      // #F34E4E      #23956f    #1AC592  	#5AEABD     #E9CD85			#E33955
      setIndex: 0,
      female: [
        {
          age: "20-29",
          color: [
            [0.138, "#F39D4E"],
            [0.142, "transparent"],
            [0.164, "#23956f"],
            [0.168, "transparent"],
            [0.193, "#1AC592"],
            [0.197, "transparent"],
            [0.226, "#5AEABD"],
            [0.23, "transparent"],
            [0.27, "#E9CD85"],
            [0.274, "transparent"],
            [0.45, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "30-39",
          color: [
            [0.138, "#F39D4E"],
            [0.142, "transparent"],
            [0.173, "#23956f"],
            [0.177, "transparent"],
            [0.207, "#1AC592"],
            [0.211, "transparent"],
            [0.245, "#5AEABD"],
            [0.249, "transparent"],
            [0.291, "#E9CD85"],
            [0.295, "transparent"],
            [0.45, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "40-49",
          color: [
            [0.138, "#F39D4E"],
            [0.142, "transparent"],
            [0.197, "#23956f"],
            [0.201, "transparent"],
            [0.237, "#1AC592"],
            [0.241, "transparent"],
            [0.275, "#5AEABD"],
            [0.279, "transparent"],
            [0.318, "#E9CD85"],
            [0.322, "transparent"],
            [0.45, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "50-59",
          color: [
            [0.138, "#F39D4E"],
            [0.142, "transparent"],
            [0.224, "#23956f"],
            [0.228, "transparent"],
            [0.269, "#1AC592"],
            [0.273, "transparent"],
            [0.303, "#5AEABD"],
            [0.307, "transparent"],
            [0.344, "#E9CD85"],
            [0.348, "transparent"],
            [0.45, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "60-",
          color: [
            [0.138, "#F39D4E"],
            [0.142, "transparent"],
            [0.231, "#23956f"],
            [0.235, "transparent"],
            [0.278, "#1AC592"],
            [0.282, "transparent"],
            [0.312, "#5AEABD"],
            [0.316, "transparent"],
            [0.353, "#E9CD85"],
            [0.357, "transparent"],
            [0.45, "#E33955"],
            [1, "transparent"],
          ],
        },
      ],
      male: [
        {
          age: "20-29",
          color: [
            [0.078, "#F39D4E"],
            [0.082, "transparent"],
            [0.104, "#23956f"],
            [0.108, "transparent"],
            [0.147, "#1AC592"],
            [0.151, "transparent"],
            [0.185, "#5AEABD"],
            [0.189, "transparent"],
            [0.23, "#E9CD85"],
            [0.234, "transparent"],
            [0.4, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "30-39",
          color: [
            [0.078, "#F39D4E"],
            [0.082, "transparent"],
            [0.144, "#23956f"],
            [0.148, "transparent"],
            [0.181, "#1AC592"],
            [0.185, "transparent"],
            [0.212, "#5AEABD"],
            [0.216, "transparent"],
            [0.248, "#E9CD85"],
            [0.252, "transparent"],
            [0.4, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "40-49",
          color: [
            [0.078, "#F39D4E"],
            [0.082, "transparent"],
            [0.173, "#23956f"],
            [0.177, "transparent"],
            [0.205, "#1AC592"],
            [0.209, "transparent"],
            [0.233, "#5AEABD"],
            [0.237, "transparent"],
            [0.265, "#E9CD85"],
            [0.269, "transparent"],
            [0.4, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "50-59",
          color: [
            [0.078, "#F39D4E"],
            [0.082, "transparent"],
            [0.19, "#23956f"],
            [0.194, "transparent"],
            [0.22, "#1AC592"],
            [0.224, "transparent"],
            [0.245, "#5AEABD"],
            [0.249, "transparent"],
            [0.277, "#E9CD85"],
            [0.281, "transparent"],
            [0.4, "#E33955"],
            [1, "transparent"],
          ],
        },
        {
          age: "60-",
          color: [
            [0.078, "#F39D4E"],
            [0.082, "transparent"],
            [0.196, "#23956f"],
            [0.2, "transparent"],
            [0.225, "#1AC592"],
            [0.229, "transparent"],
            [0.251, "#5AEABD"],
            [0.255, "transparent"],
            [0.283, "#E9CD85"],
            [0.287, "transparent"],
            [0.4, "#E33955"],
            [1, "transparent"],
          ],
        },
      ],
    };
  },
  mounted() {
    this.setChart();
    // console.log("this.age");
    // console.log(this.age);
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    calculateColor() {
      let help = this.gender === "male" ? this.male : this.female;

      if (this.age >= 60) {
        this.setIndex = help.length - 1;
        return;
      }
      for (let i = 0; i < help.length - 1; i++) {
        let arr = help[i].age.split("-");
        if (this.age >= arr[0] && this.age <= arr[1]) {
          // console.log(arr);
          this.setIndex = i;
          return;
        }
      }
    },
    setChart() {
      this.calculateColor();
      var chartDom = this.$refs.mainFat;
      this.myChart = echarts.init(chartDom);
      var option;

      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%",
        // },
        series: [
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            startAngle: 90,
            endAngle: -270,
            splitNumber: 5,
            min: 0,
            max: 100,
            itemStyle: {},
            axisLine: {
              //   show: false,
              //   symbolOffset: [10, 10],

              symbolOffset: [0, 0],
              //   onZeroAxisIndex: -60,

              lineStyle: {
                width: this.isModal ? 25 : 15,
                // shadowOffsetY: 4,

                // shadowOffsetX: 5,
                // color: this.isDarkTheme ? [[1, "#34363E"]] : [[1, "#EBEBEB"]],
                color: [
                  //   [0.1, "#24C48F"],
                  //   [0.105, this.isDarkTheme ? "#52545A" : "#EBEBEB"],
                  //   [0.2, "#F2E679"],
                  //   [0.205, this.isDarkTheme ? "#52545A" : "#EBEBEB"],
                  //   [0.3, "#F39947"],
                  //   [0.305, this.isDarkTheme ? "#52545A" : "#EBEBEB"],
                  //   [0.45, "#E94260"],
                  [1, this.isDarkTheme ? "#52545A" : "#EBEBEB"],
                ],
              },
            },
            pointer: {
              show: false,
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: "18%",
              width: 5,

              offsetCenter: [0, "-82%"],
              itemStyle: {
                color: "#ffffffdc",
              },
            },
            splitLine: {
              show: false,
              //   distance: -20,
              //   length: 20,
              //   lineStyle: {
              //     color: this.isDarkTheme ? "#52545A" : "#EBEBEB",
              //     width: 2,
              //   },
            },
            axisTick: {
              show: true,
              distance: this.isModal ? 10 : 7,
              length: 1,
              lineStyle: {
                color: this.isDarkTheme ? "#A6A6A6" : "#000",
                width: this.isModal ? 4 : 2,
              },
            },
            axisLabel: {
              show: false,
              // color: this.isDarkTheme ? "#BCBDBF" : "#000",
              // distance: -10,
              // fontSize: 10,
              // fontWeight: "bold",
              // // formatter: "{value}" + " %",
              // formatter: function (value) {
              //   if (value === 185) {
              //     return value + " %";
              //   }
              //   return "";
              // },
            },
            progress: {
              show: false,
              // roundCap: false,
              // width: 30,
            },

            title: {
              show: false,
              //   offsetCenter: ["0%", "-18%"],
              //   color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              //   fontSize: 9,
            },
            detail: {
              show: false,
              //   valueAnimation: true,
              //   offsetCenter: ["0%", "13%"],
              //   // color: "#fff",
              //   fontSize: 27,
              //   fontWeight: 600,
              //   color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              //   borderColor: "none",
              //   borderRadius: 20,
              //   formatter: "{value}" + "%",
            },
            data: [
              {
                value: this.fatThis,
                // name: "SCORE",
                name: "Procenat masti",
              },
            ],
          },
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            startAngle: 90,
            endAngle: -270,
            splitNumber: 10,
            min: 0,
            max: 100,
            radius: "84%",
            itemStyle: {
              //   color: "blue",
            },
            axisLine: {
              lineStyle: {
                width: this.isModal ? 43 : 25,
                // color: this.isDarkTheme ? [[1, "#34363E"]] : [[1, "#EBEBEB"]],
                color:
                  this.gender === "male"
                    ? this.male[this.setIndex].color
                    : this.female[this.setIndex].color,
                //  [
                //   [0.1, "#24C48F"],
                //   [0.105, "transparent"],
                //   [0.2, "#F2E679"],
                //   [0.205, "transparent"],
                //   [0.3, "#F39947"],
                //   [0.305, "transparent"],
                //   [0.45, "#E94260"],
                //   [1, "transparent"],
                // ],
              },
            },
            pointer: {
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: this.isModal ? "26%" : "27%",
              width: 5,

              offsetCenter: this.isModal ? [0, "-74%"] : [0, "-73%"],
              itemStyle: {
                color: "#ffffffdc",
                shadowColor: "rgba(255, 255, 255)",
                shadowBlur: 10,
              },
            },
            splitLine: {
              show: false,
              //   distance: -20,
              //   length: 20,
              //   lineStyle: {
              //     color: this.isDarkTheme ? "#52545A" : "#EBEBEB",
              //     width: 2,
              //   },
            },
            axisTick: {
              show: false,
              // distance: 5,
              // length: 1,
              // lineStyle: {
              //   color: this.isDarkTheme ? "#fff" : "#000",
              //   width: 1,
              // },
            },
            axisLabel: {
              color: this.isDarkTheme ? "#BCBDBF" : "#000",
              distance: -10,
              fontSize: 10,
              fontWeight: "bold",
              // formatter: "{value}" + " %",
              formatter: function (value) {
                if (value === 185) {
                  return value + " %";
                }
                return "";
              },
            },
            progress: {
              show: false,
              // roundCap: false,
              // width: 30,
            },

            title: {
              offsetCenter: ["0%", "-18%"],
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              fontSize: this.isModal ? 15 : 9,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "13%"],
              // color: "#fff",
              fontSize: this.isModal ? 44 : 27,
              fontWeight: 600,
              color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              borderColor: "none",
              borderRadius: 20,
              formatter: "{value}" + "%",
            },
            data: [
              {
                value: this.fatThis,
                // name: "SCORE",
                name: "Procenat masti",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.fat {
  position: relative;
  display: flex;
  width: 190px;
  height: 190px;
  overflow: hidden;
  //  color: #37C78F;
  #mainFat {
    position: absolute;
    top: -10px;
    left: -20px;
    width: 220px;
    height: 220px;
  }
}
.fat.modal {
  position: initial;
  width: 400px;
  height: 400px;
  #mainFat {
    position: initial;
    width: 400px;
    height: 400px;
  }
}
</style>
