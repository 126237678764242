<template>
  <div :class="{ 'bim-chart': true, modal: isModal }">
    <div ref="main" id="main"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "bim-chart",
  props: {
    bmi: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  data() {
    return {
      theme: this.isDarkTheme ? "dark" : "light",
      myChart: null,
    };
  },
  created() {
    this.bmiThis = parseFloat(String(this.bmi).slice(0, 2));
  },
  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setChart() {
      var chartDom = this.$refs.main; //document.getElementById("main");
      //   var myChart = echarts.init(chartDom, this.theme);
      this.myChart = echarts.init(chartDom);
      // toolbox.feature.saveAsImage.connectedBackgroundColor = "#fff";
      var option;

      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        // grid: {
        //   backgroundColor: "white",
        //   show: true,
        // },
        toolbox: {
          // show: true,
          // feature: {
          //   saveAsImage: {
          //     connectedBackgroundColor: "#fff",
          //     backgroundColor: "#fff",
          //   },
          // },
        },
        textStyle: {
          fontFamily: "Archivo",
        },
        series: [
          {
            type: "gauge",
            // center: ["50%", "60%"],
            startAngle: 180,
            endAngle: -120,
            min: 10,
            max: 40,
            // splitNumber: 5,
            splitNumber: 12,
            axisLine: {
              lineStyle: {
                width: this.isModal ? 123 : 90,
                color: [
                  [0.2, "#F39947"],
                  [0.3, "#1AC691"],
                  [0.4, "#F2E679"],
                  [0.6, "#E33955"],
                ],
              },
            },
            pointer: {
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: "40%",
              width: 5,

              offsetCenter: [0, "-40%"],
              itemStyle: {
                color: "#ffffffdc",
              },
            },
            axisTick: {
              show: false,
              distance: -30,
              length: 8,
              lineStyle: {
                color: "#fff",
                width: 0,
              },
            },
            splitLine: {
              distance: -0,
              length: 30,
              lineStyle: {
                // color: "#fff",
                width: 0,
              },
            },
            axisLabel: {
              color: this.isDarkTheme ? "#000" : "#fff",
              distance: this.isModal ? 20 : 3,
              fontSize: this.isModal ? 15 : 13,
              fontWeight: "bold",

              formatter: function (value) {
                if (value === 15) {
                  return "\n< 20";
                } else if (value === 22.5) {
                  return "20 - 25";
                } else if (value === 27.5) {
                  return "25 - 30";
                } else if (value === 35) {
                  return "\n > 30";
                }
                return "";
              },

              // formatter: function (value) {
              //   if (value === 16) {
              //     return "\n\n< 20";
              //   } else if (value === 22) {
              //     return "  20 - 25";
              //   } else if (value === 28) {
              //     return "25 - 30  ";
              //   } else if (value === 34) {
              //     return "\n\n > 30";
              //   }
              //   return "";
              // },
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}",
              color: "inherit",
            },
            data: [
              {
                value: this.bmiThis,
                name: "BMI",
                title: {
                  offsetCenter: this.isModal ? ["0%", "-2%"] : ["0%", "0%"],
                  color: this.isDarkTheme ? "#fff" : "#2B2B2E",
                  fontSize: this.isModal ? 15 : 10,
                  fontWeight: 700,
                },
                detail: {
                  valueAnimation: true,
                  offsetCenter: ["0%", "-18%"],
                  color: this.isDarkTheme ? "#fff" : "#2B2B2E",
                  fontWeight: 700,
                  fontSize: this.isModal ? 43 : 33,
                  formatter: "{value}",
                },
              },
            ],
          },
        ],
      };
      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.bim-chart {
  width: 400px;
  height: 220px;
  overflow: hidden;
  #main {
    width: 100%;
    height: 400px;
  }
}
.bim-chart.modal {
  // width: 550px;
  width: 430px;
  height: 320px;
  overflow: hidden;
  position: relative;
  #main {
    position: absolute;
    left: -60px;
    width: 550px;
    height: 550px;
  }
}
</style>
