// VER: 2022-4
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import customInputFormats from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import localeData from "dayjs/plugin/localeData";
import "dayjs/locale/sr";

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(customInputFormats);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.locale("sr");

export class nDate {
  dateInstance: dayjs.Dayjs;
  defaultOutputFormat = "DD. MMM YYYY.";

  constructor(dateString: string, inFormat = "", isUtc = true) {
    if (inFormat) {
      const dateLogic = isUtc
        ? dayjs.utc(dateString, inFormat)
        : dayjs(dateString, inFormat);
      const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
      this.dateInstance = dateString ? dateLogic : todayDateLogic;
    } else {
      const dateLogic = isUtc ? dayjs.utc(dateString) : dayjs(dateString);
      const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
      this.dateInstance = dateString ? dateLogic : todayDateLogic;
    }
  }

  formatDate(outFormat = this.defaultOutputFormat) {
    return getFormattedDate(this.dateInstance, outFormat);
  }

  formatIsoDate(timeFormat = "") {
    return getFormattedIsoDate(this.dateInstance, timeFormat);
  }

  changeGlobalLocale(locStr: string) {
    return dayjs.locale(locStr);
  }

  changeLocale(locStr: string) {
    return dayjs(this.dateInstance).locale(locStr);
  }

  changeOutputFormat(outFormat = "") {
    if (outFormat) {
      this.defaultOutputFormat = outFormat;
      return this;
    }
    console.error("Output format needed");
  }

  static getFormattedDate(date: string, outFormat = "l") {
    const dateObj = getDateInstance(date);
    return getFormattedDate(dateObj, outFormat);
  }

  static getFormattedIsoDate(date: string, timeFormat = "") {
    const dateObj = getDateInstance(date);
    return getFormattedIsoDate(dateObj, timeFormat);
  }

  static getDateInstance(date: string, isUtc = true) {
    return getDateInstance(date, isUtc);
  }

  static getDateLocaleData(date: string, isUtc = true) {
    const dateObj = getDateInstance(date, isUtc);
    return getDateLocaleData(dateObj);
  }

  static getRelativeTimeFromNow(date: string, isUtc = true) {
    return getRelativeTimeFromNow(date, isUtc);
  }

  static getDateDiff(date: string, isUtc = true) {
    return getDateDiff(date, isUtc);
  }

  static isSameDate(date: string, isUtc = true) {
    return isSameDate(date, isUtc);
  }

  static getFormatHasTime(format: string) {
    return getFormatHasTime(format);
  }
}

function getDateInstance(date: string, isUtc = true) {
  // Get date from string / today
  const dateLogic = isUtc ? dayjs.utc(date) : dayjs(date);
  const todayDateLogic = isUtc ? dayjs.utc() : dayjs();
  return date ? dateLogic : todayDateLogic;
}

function getFormattedDate(dateInstance: dayjs.Dayjs, outFormat = "l") {
  return dateInstance.format(outFormat);
}

function getFormattedIsoDate(dateInstance: dayjs.Dayjs, timeFormat = "") {
  return timeFormat
    ? dateInstance.format(`YYYY-MM-DD ${timeFormat}`)
    : dateInstance.format("YYYY-MM-DD");
}

function getDateLocaleData(dateInstance: dayjs.Dayjs) {
  // https://day.js.org/docs/en/plugin/locale-data
  return dateInstance?.localeData();
}

function getRelativeTimeFromNow(date: string, isUtc = true) {
  if (isUtc) {
    return dayjs.utc(date).fromNow();
  } else {
    return dayjs(date).fromNow();
  }
}

function getDateDiff(date: string, isUtc = true) {
  if (isUtc) {
    const formattedLocal = dayjs.utc(date).local();
    return dayjs().diff(formattedLocal, "days");
  } else {
    const formattedLocal = dayjs(date).local();
    return dayjs().diff(formattedLocal, "days");
  }
}

function isSameDate(date: string, isUtc = true) {
  return getDateDiff(date, isUtc) < 1;
}

function getFormatHasTime(format: string) {
  const timeFormats = ["H", "h", "m", "s"];
  return timeFormats.some((tf) => format.includes(tf));
}
