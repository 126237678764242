<template>
  <div class="my-toast" :class="[type, { 'dark-theme': isDarkTheme }]">
    <slot>
      <div class="line"></div>
      <div class="content">
        <div class="icon">
          <template v-if="type === 'error'">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16.5" fill="#EA475E" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.077 10.5328C20.6628 9.94706 21.6125 9.94706 22.1983 10.5328C22.7841 11.1186 22.7841 12.0684 22.1983 12.6542L18.4867 16.3658L22.1986 20.0777C22.7844 20.6635 22.7844 21.6132 22.1986 22.199C21.6128 22.7848 20.663 22.7848 20.0772 22.199L16.3653 18.4871L12.6534 22.199C12.0676 22.7848 11.1179 22.7848 10.5321 22.199C9.94633 21.6132 9.94633 20.6635 10.5321 20.0777L14.244 16.3658L10.5324 12.6542C9.9466 12.0684 9.9466 11.1186 10.5324 10.5328C11.1182 9.94706 12.0679 9.94706 12.6537 10.5328L16.3653 14.2445L20.077 10.5328Z"
                fill="white"
              />
            </svg>
          </template>
          <template v-if="type === 'success'">
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16.5" cy="16.5" r="16.5" fill="#47EAAF" />
              <path
                d="M11 16.5L14.5 20L21.5 13"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </template>
        </div>
        <div class="text-content">
          <div class="title">{{ parsedTitleMsg }}</div>
          <div class="text">
            {{ msg }}
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "error",
    },
    isDarkTheme: Boolean,
    titleMsg: String,
    msg: String,
  },
  computed: {
    parsedTitleMsg() {
      return this.titleMsg || this.type === "error"
        ? "Došlo je do greške!"
        : "Uspeh";
    },
  },
};
</script>

<style lang="scss" scoped>
.my-toast {
  display: flex;
  height: 100%;
  width: 100%;
  font-family: "Archivo";

  .line {
    height: 100%;
    width: 5px;
    border-radius: 10px 0 0 10px;
  }

  .content {
    background-color: #f2f5ff80;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 0 10px 10px 0;
    border-color: #dce0e8;
    border-top: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    overflow: hidden;

    .text-content {
      font-size: 16px;
      font-style: normal;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .title {
        color: #172841;
        font-weight: 600;
        line-height: 17px;
      }

      .text {
        color: #49576c;
      }
    }
  }

  &.success {
    .line {
      background: #47eaaf;
    }
  }

  &.error {
    .line {
      background: #ea475e;
    }
  }

  &.dark-theme {
    .content {
      background-color: #33353b;
      border: 0;

      .title {
        color: white;
      }

      .text {
        color: #d3d3d3;
      }
    }
  }
}
</style>
