<template>
  <div :class="{ physicalActivity: true, modal: isModal }">
    <div ref="mainActivity" id="mainActivity"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { graphic } from "echarts";
export default {
  name: "physicalActivity",
  props: {
    setColor: {},
    isDarkTheme: {},
    activity: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  created() {
    this.activityThis = parseFloat(String(this.activity).slice(0, 3));
  },
  data() {
    return {
      myChart: null,
      setBigColor: 0,
      activityThis: 0,
      physicalActivity: [
        {
          value: 150,
          color: [
            [0.33, "#EA475E"],
            [0.67, "transparent"],
            [1, "transparent"],
          ],
        },
        {
          value: 300,
          color: [
            [0.33, "transparent"],
            [0.67, "#3DDAA6"],
            [1, "transparent"],
          ],
        },
        {
          value: 15000,
          color: [
            [0.33, "transparent"],
            [0.67, "transparent"],
            [1, "#24C48F"],
          ],
        },
      ],
    };
  },
  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
    activityThis: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    checkColor(val) {
      for (let i = 0; i < this.physicalActivity.length; i++) {
        if (this.physicalActivity[i].value >= val) {
          // console.log(this.physicalActivity[i].value);
          this.setBigColor = i;
          // console.log("setBigColor" + this.setBigColor);
          return;
        }
      }
    },
    // setsize(i) {
    //   if (i < 39) {
    //     setTimeout(() => {
    //       console.log(i);
    //       this.setsize(i + 1);
    //       return i;
    //     }, 100);
    //   }
    //   return i;
    // },
    setChart() {
      this.checkColor(this.activityThis);
      var chartDom = this.$refs.mainActivity;
      this.myChart = echarts.init(chartDom);
      var option;

      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%",
        // },
        series: [
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            startAngle: 90,
            endAngle: -270,
            splitNumber: 3,
            min: 0,
            max: 450,
            itemStyle: {},
            axisLine: {
              lineStyle: {
                width: this.isModal ? 60 : 30,
                color: [
                  [0.33, "#EA475E"],
                  [0.67, "#3DDAA6"],
                  [1, "#24C48F"],
                ],
              },
            },
            pointer: {
              show: false,
              //   show: this.activity > 150 ? true : false,
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: "37%",
              width: 15,

              offsetCenter: [0, "-63%"],
              itemStyle: {
                color: "rgba(255, 255, 255, 0.37)",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false,
              color: this.isDarkTheme ? "#BCBDBF" : "#000",
              distance: -15,
              fontSize: 10,
              fontWeight: "bold",
              //   formatter: "{value}" + " min",
              formatter: function (value) {
                if (value === 450) {
                  return "";
                }
                return value + "\nmin";
              },
            },
            progress: {
              // show: true,
              // roundCap: false,
              // width: 30,
            },

            title: {
              offsetCenter: this.isModal ? ["0%", "-15%"] : ["0%", "-18%"],
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              fontSize: this.isModal ? 14 : 9,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["-13%", "13%"],
              // color: "#fff",
              fontSize: this.isModal ? 40 : 27,
              fontWeight: 600,
              color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              borderColor: "none",
              borderRadius: 20,
              formatter: "{value}",
            },
            data: [
              {
                value: this.activityThis,
                // name: "SCORE",
                name: "Fizička aktivnost",
              },
            ],
          },
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            startAngle: 90,
            endAngle: -270,
            splitNumber: 3,
            min: 0,
            max: 450,
            radius: "84%",
            itemStyle: {},

            axisLine: {
              lineStyle: {
                width: 39, // this.setsize(1),
                color: this.physicalActivity[this.setBigColor].color,
                //  [
                //   [0.33, "#EA475E"],
                //   [0.67, "transparent"],
                //   [1, "transparent"],
                // ],
              },
            },
            pointer: {
              //   show: this.activity > 150 ? false : true,
              show: true,
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: "46%",
              width: 3,

              offsetCenter: [0, "-54%"],
              itemStyle: {
                color: "rgba(255, 255, 255)",
                shadowColor: "rgba(255, 255, 255)",
                shadowBlur: this.isModal ? 10 : 10,
              },
            },
            axisTick: {
              show: false,
              // distance: -80,
              // length: 8,
              // lineStyle: {
              //   color: "red",
              //   width: 0,
              // },
            },
            splitLine: {
              show: false,
              distance: -20,
              length: 8,
              lineStyle: {
                // color: "#fff",
                width: 2,
              },
            },
            axisLabel: {
              color: this.isDarkTheme ? "#BCBDBF" : "#000",
              distance: this.isModal ? -23 : -15,
              fontSize: this.isModal ? 15 : 10,
              fontWeight: "bold",
              //   formatter: "{value}" + " min",
              formatter: function (value) {
                if (value === 450) {
                  return "";
                }
                return value + "\nmin";
              },
            },
            progress: {
              // show: true,
              // roundCap: false,
              // width: 30,
            },

            title: {
              show: false,
              offsetCenter: ["0%", "-18%"],
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              fontSize: 9,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["33%", "16%"],
              // color: "#fff",
              fontSize: this.isModal ? 14 : 7,
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              borderColor: "none",
              borderRadius: 20,
              formatter: "min/ned",
              //   formatter: "{value|value}{unit|km/h}", // + "min/ned",
              //   formatter: function (value) {
              //     return "{value|" + value.toFixed(0) + "}{unit|km/h}";
              //   },
            },
            data: [
              {
                value: this.activityThis,
                // name: "SCORE",
                name: "Fizička aktivnost",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.physicalActivity {
  position: relative;
  display: flex;
  width: 210px;
  height: 230px;
  overflow: hidden;
  #mainActivity {
    position: absolute;
    top: 10px;
    left: 0;
    width: 210px;
    height: 240px;
  }
}
.physicalActivity.modal {
  // position: initial;
  width: 400px;
  height: 420px;
  #mainActivity {
    // position: initial;
    width: 400px;
    height: 430px;
  }
}
</style>
