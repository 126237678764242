<template>
  <div :class="{ sleep: true, modal: isModal }">
    <div ref="mainsleep" id="mainSleep"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { graphic } from "echarts";
export default {
  name: "efficiencySleep",
  props: {
    isDarkTheme: {},
    sleepEff: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  data() {
    return {
      myChart: null,
      sleepEffThis: 0,
    };
  },
  created() {
    this.sleepEffThis = parseFloat(String(this.sleepEff).slice(0, 3));
  },
  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setChart() {
      var chartDom = this.$refs.mainsleep;
      this.myChart = echarts.init(chartDom);
      var option;

      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%",
        // },
        series: [
          {
            type: "gauge",
            // startAngle: 180,
            // endAngle: 0,
            startAngle: 90,
            endAngle: -270,
            splitNumber: 100,
            itemStyle: {},
            axisLine: {
              lineStyle: {
                width: this.isModal ? 38 : 20,
                // color: this.isDarkTheme ? [[1, "#34363E"]] : [[1, "#EBEBEB"]],
                color: [
                  [0.53, "#EA415A"],
                  [0.7, "#F39C4E"],
                  [1, "#33C78C"],
                ],
              },
            },
            pointer: {
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: "26%",
              width: this.isModal ? 10 : 5,

              offsetCenter: [0, "-74%"],
              itemStyle: {
                color: "#ffffffdc",
                shadowColor: "rgba(255, 255, 255)",
                shadowBlur: 10,
              },
            },
            axisTick: {
              show: false,

              distance: this.isModal ? 11 : 7,
              length: 1,
              lineStyle: {
                color: this.isDarkTheme ? "#A6A6A6" : "#000",
                width: this.isModal ? 4 : 2,
              },
            },
            splitLine: {
              show: false,
              distance: -20,
              length: 8,
              lineStyle: {
                // color: "#fff",
                width: 2,
              },
            },
            axisLabel: {
              color: this.isDarkTheme ? "#BCBDBF" : "#000",
              distance: this.isModal ? -20 : -5,
              fontSize: this.isModal ? 15 : 10,
              fontWeight: "bold",
              //   formatter: "{value}" + " %",
              formatter: function (value) {
                if (value === 53 || value === 70 || value === 100) {
                  return value;
                }
                return "";
              },
            },
            progress: {
              // show: true,
              // roundCap: false,
              // width: 30,
            },

            title: {
              offsetCenter: ["0%", "-18%"],
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              fontSize: this.isModal ? 14 : 9,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "13%"],
              // color: "#fff",
              fontSize: this.isModal ? 44 : 27,
              fontWeight: 600,
              color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              borderColor: "none",
              borderRadius: 20,
              formatter: "{value}",
            },
            data: [
              {
                value: this.sleepEffThis,
                // name: "SCORE",
                name: "Mentalno stanje",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.sleep {
  //   width: 182px;
  //   height: 182px;
  position: relative;
  width: 190px;
  height: 195px;
  overflow: hidden;
  #mainSleep {
    position: absolute;
    top: -7px;
    left: -5px;
    width: 210px;
    height: 210px;
  }
}
.sleep.modal {
  position: initial;
  width: 400px;
  height: 400px;

  #mainSleep {
    position: initial;
    width: 400px;
    height: 400px;
  }
}
</style>
