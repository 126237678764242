<template>
  <div :class="{ 'wrapp-line-charts': true, modal: isModal }">
    <div
      class="title"
      :style="{ color: this.isDarkTheme ? '#fff' : '#2B2B2E' }"
    >
      Lipidni profil
    </div>
    <LIneChart
      v-for="(value, index) in lipidProfile"
      :key="index"
      :value="value"
      :isDarkTheme="isDarkTheme"
      :animationDuration="animationDuration"
      :isModal="isModal"
    />
  </div>
</template>

<script>
export default {
  props: {
    lipidProfile: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.wrapp-line-charts {
  width: 400px;
  min-height: 180px;
  height: fit-content;
  margin-top: 0;

  .title {
    font-family: "Archivo", "Times New Roman", Times, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 18px;
    text-align: left;

    color: #ffffff;
    margin-bottom: 20px;
  }
}
.wrapp-line-charts.modal {
  width: fit-content;

  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 28px;
    text-align: left;

    color: #ffffff;
    margin-bottom: 40px;
  }
}
</style>
