<template>
  <transition name="fade" mode="out-in">
    <div
      class="wrapper-route"
      :style="wrapperStyle"
      :class="{ dark: isDarkTheme }"
      :key="$route.name"
    >
      <nav-bar
        :fullName="getFullName"
        :reportDate="getReportDate"
        :reportNumber="getReportNumber"
        :dateBirth="getDob"
        :is-dark-theme="isDarkTheme"
        v-bind="navProps"
        v-if="hasNav"
      ></nav-bar>
      <!-- @change-theme="onChangeTheme" -->
      <template v-if="canDisplayChildComps">
        <slot />
      </template>

      <bottom-nav
        :is-dark-theme="isDarkTheme"
        v-bind="navProps"
        v-if="hasBottomNav"
      ></bottom-nav>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import { nDate } from "../../assets/js/dateHelper.ts";

export default {
  props: {
    routeMeta: {
      type: Object,
    },
    isDarkTheme: Boolean,
    themeColors: Array,
  },
  data() {
    return {
      canDisplayChildComps: false,
    };
  },
  computed: {
    ...mapGetters(["patientData", "isValidPatientData"]),
    wrapperStyle() {
      const isDark = this.isDarkTheme && this.routeMeta.hasDarkTheme;
      const bgcolor = isDark ? this.themeColors[1] : this.themeColors[0];
      return {
        background: bgcolor,
      };
    },
    hasNav() {
      return this.routeMeta.hasNav;
    },
    hasBottomNav() {
      return this.routeMeta.hasBottomNav;
    },
    navProps() {
      return this.routeMeta;
    },
    getFullName() {
      return `${this.patientData.firstName || ""} ${
        this.patientData.lastName || ""
      }`;
    },
    getReportDate() {
      const date = new nDate();
      return date.formatDate("DD. MMM YYYY.");
    },
    getReportNumber() {
      // TODO Phase 2 ?
      return `885D9882LK`;
    },
    getDob() {
      const date = new nDate(this.patientData.dateOfBirth, "DD/MM/YYYY");
      return date.formatDate("DD. MMM YYYY.");
    },
  },
  mounted() {
    this.initPatientData();
    this.procCheckValidData();
    this.canDisplayChildComps = true;
  },
  watch: {
    $route(to, from) {
      if (to.meta.hasPatientInfo) {
        this.procCheckValidData();
      }
    },
  },
  methods: {
    initPatientData() {
      const pds = sessionStorage.patientDataStore;
      if (pds) {
        try {
          const parsedData = JSON.parse(pds);
          const wholePatientData = { ...this.patientData, ...parsedData };
          this.$store.commit("setPatientData", wholePatientData);
          return true;
        } catch (err) {
          console.log("🐜 Invalid stored data", err.message);
          return false;
        }
      }
    },
    procCheckValidData() {
      if (!this.isValidPatientData && this.$route.meta.hasPatientInfo) {
        this.$router.replace({
          name: "FormPage",
        });
      }
    },
    // onChangeTheme(evt) {
    //   this.$emit("change-theme", evt);
    // },
  },
};
</script>

<style lang="scss">
.wrapper-route {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: background 0.12s ease;
}

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.1s ease;
// }

// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
</style>
