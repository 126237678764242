<template>
  <div :class="{ muscle: true, modal: isModal }">
    <div ref="mainM" id="mainM"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { graphic } from "echarts";
export default {
  name: "muscleStrength",
  props: {
    isDarkTheme: {},
    muscle: {},
    gender: {},
    age: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  data() {
    return {
      myChart: null,
      muscleThis: 0,
      setColor: [
        {
          offset: 0,
          color: "#37C78F",
        },
        {
          offset: 0.4,
          color: "#37C78F",
        },
        {
          offset: 0.6,
          color: "#E9CD85",
        },

        {
          offset: 0.8,
          color: "#CD1939",
        },
        {
          offset: 1,
          color: "#CD1939",
        },
      ], //["#37C78F", "#E9CD85", "#CD1939"], //green yellow red
      maleMuscle: [
        // Jačina mišićne snage šake za muškarce u kg
        // STAROST SLABO NORMALNO JAKO
        // the upper limit is shown in l, m , h level is up from m
        { age: "10-11", l: 12.6, m: 22.4 },
        { age: "12-13", l: 19.4, m: 31.2 },
        { age: "14-15", l: 28.5, m: 44.3 },
        { age: "16-17", l: 32.6, m: 52.4 },
        { age: "18-19", l: 35.7, m: 55.5 },
        { age: "20-24", l: 36.8, m: 56.6 },
        { age: "25-29", l: 37.7, m: 57.5 },
        { age: "30-34", l: 36.0, m: 55.8 },
        { age: "35-39", l: 35.8, m: 55.6 },
        { age: "40-44", l: 35.5, m: 55.3 },
        { age: "45-49", l: 34.7, m: 54.5 },
        { age: "50-54", l: 32.9, m: 50.7 },
        { age: "55-59", l: 30.7, m: 48.5 },
        { age: "60-64", l: 30.2, m: 48.0 },
        { age: "65-69", l: 28.2, m: 44.0 },
        { age: "70-99", l: 21.3, m: 35.1 },
      ],
      femaleMuscle: [
        // Jačina mišićne snage šake za žene u kg
        // STAROST SLABO NORMALNO JAKO
        { age: "10-11", l: 11.8, m: 21.6 },
        { age: "12-13", l: 14.6, m: 24.4 },
        { age: "14-15", l: 15.5, m: 27.3 },
        { age: "16-17", l: 17.2, m: 29.0 },
        { age: "18-19", l: 19.2, m: 31.0 },
        { age: "20-24", l: 21.5, m: 35.3 },
        { age: "25-29", l: 25.6, m: 41.4 },
        { age: "30-34", l: 21.5, m: 35.3 },
        { age: "35-39", l: 20.3, m: 34.1 },
        { age: "40-44", l: 18.9, m: 32.7 },
        { age: "45-49", l: 18.6, m: 32.4 },
        { age: "50-54", l: 18.1, m: 31.9 },
        { age: "55-59", l: 17.7, m: 31.5 },
        { age: "60-64", l: 17.2, m: 31.0 },
        { age: "65-69", l: 15.4, m: 27.2 },
        { age: "70-99", l: 14.7, m: 24.5 },
      ],
    };
  },
  created() {
    this.muscleThis = parseFloat(String(this.muscle).slice(0, 3));
  },
  mounted() {
    this.setChart();
  },
  watch: {
    isDarkTheme: {
      handler(newVal) {
        if (this.myChart) {
          echarts.dispose(this.myChart);
          this.setChart();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setColorChart() {
      let help = this.gender === "male" ? this.maleMuscle : this.femaleMuscle;
      for (let i = 0; i < help.length - 1; i++) {
        let arr = help[i].age.split("-");

        if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].l > this.muscleThis
        ) {
          // console.log("1------------------------ 1");
          this.setColor = [
            // {
            //   offset: 0.1,
            //   color: "#E9CD85",
            // },
            {
              offset: 0.2,
              color: "#CD1939",
            },

            {
              offset: 1,
              color: "#CD1939",
            },
          ];
          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m >= this.muscleThis
        ) {
          // console.log("2------------------------ 2");
          this.setColor = [
            {
              offset: 0.09,
              color: "#38D59C",
            },
            {
              offset: 0.35,
              color: "#E9CD85",
            },
            {
              offset: 0.6,
              color: "#CD1939",
            },

            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];
          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m < this.muscleThis &&
          help[i].m + 10 >= this.muscleThis
        ) {
          // console.log("3------------------------ 3");
          this.setColor = [
            {
              offset: 0.1,
              color: "#38D59C",
            },
            {
              offset: 0.4,
              color: "#E9CD85",
            },
            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];
          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m + 10 < this.muscleThis &&
          help[i].m + 25 >= this.muscleThis
        ) {
          // console.log("4------------------------ 4");
          this.setColor = [
            {
              offset: 0.1,
              color: "#38D59C",
            },
            {
              offset: 0.5,
              color: "#E9CD85",
            },
            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];

          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m + 25 < this.muscleThis &&
          help[i].m + 40 >= this.muscleThis
        ) {
          // console.log("5------------------------ 5");
          this.setColor = [
            {
              offset: 0.1,
              color: "#38D59C",
            },
            {
              offset: 0.3,
              color: "#38D59C",
            },
            {
              offset: 0.55,
              color: "#E9CD85",
            },
            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];
          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m + 40 < this.muscleThis &&
          help[i].m + 60 >= this.muscleThis
        ) {
          // console.log("5------------------------ 6");
          this.setColor = [
            {
              offset: 0.1,
              color: "#38D59C",
            },
            {
              offset: 0.4,
              color: "#38D59C",
            },
            {
              offset: 0.6,
              color: "#E9CD85",
            },
            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];
          return;
        } else if (
          this.age >= arr[0] &&
          this.age <= arr[1] &&
          help[i].m + 60 < this.muscleThis &&
          help[i].m + 99 >= this.muscleThis
        ) {
          // console.log("5------------------------ 7");
          this.setColor = [
            {
              offset: 0.1,
              color: "#38D59C",
            },
            {
              offset: 0.4,
              color: "#38D59C",
            },
            {
              offset: 0.65,
              color: "#E9CD85",
            },
            {
              offset: 0.9,
              color: "#CD1939",
            },
          ];
          return;
        }
      }
    },
    setChart() {
      this.setColorChart();
      var chartDom = this.$refs.mainM;
      this.myChart = echarts.init(chartDom);
      var option;

      option = {
        animationDuration: this.animationDuration,
        backgroundColor: this.isDarkTheme ? "transparent" : "#fff",
        // tooltip: {
        //   formatter: "{a} <br/>{b} : {c}%",
        // },
        series: [
          {
            // name: "muscle",
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            itemStyle: {
              // color: "#58D9F9",
              // shadowColor: "rgba(0,138,255,0.45)",
              // shadowBlur: 10,
              // shadowOffsetX: 2,
              // shadowOffsetY: 2,
              color: new graphic.LinearGradient(1, 0, 0, 1, this.setColor),
              // [
              //   {
              //     offset: 0,
              //     color: this.setColor[0],
              //   },
              //   {
              //     offset: 0.4,
              //     color: this.setColor[0],
              //   },
              //   {
              //     offset: 0.6,
              //     color: this.setColor[1],
              //   },

              //   {
              //     offset: 0.8,
              //     color: this.setColor[2],
              //   },
              //   {
              //     offset: 1,
              //     color: this.setColor[2],
              //   },
              // ]
            },
            axisLine: {
              lineStyle: {
                width: this.isModal ? 90 : 60,
                color: this.isDarkTheme ? [[1, "#34363E"]] : [[1, "#EBEBEB"]],
                //     color: [
                //       [0.25, "#FF6E76"],
                //       [0.5, "#FDDD60"],
                //       [0.75, "#58D9F9"],
                //       [1, "#7CFFB2"],
                //     ],
              },
            },
            pointer: {
              icon: "path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z",
              length: this.isModal ? "36%" : "30%",
              width: 7,

              offsetCenter: this.isModal ? [0, "-56%"] : [0, "-60%"],
              itemStyle: {
                color: this.isDarkTheme ? "#ffffffdc" : "#646466",
              },
            },
            axisTick: {
              show: false,
              // distance: -80,
              // length: 8,
              // lineStyle: {
              //   color: "red",
              //   width: 0,
              // },
            },
            splitLine: {
              show: false,
              distance: -30,
              length: 8,
              lineStyle: {
                // color: "#fff",
                width: 2,
              },
            },
            axisLabel: {
              color: this.isDarkTheme ? "#BCBDBF" : "#000",
              distance: this.isModal ? -19 : !this.isDarkTheme ? -22 : -9,
              fontSize: this.isModal ? 15 : 10,
              fontWeight: !this.isDarkTheme ? "" : "bold",
              formatter: "{value}" + "kg",
            },
            progress: {
              show: true,
              roundCap: false,
              width: this.isModal ? 90 : 60,
            },

            title: {
              offsetCenter: ["0%", "-2%"],
              color: this.isDarkTheme ? "#F3F3F3" : "#2B2B2E",
              fontSize: this.isModal ? 16 : 10,
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "-20%"],
              // color: "#fff",
              fontSize: this.isModal ? 43 : 31,
              fontWeight: 700,
              color: this.isDarkTheme ? "#fff" : "#2B2B2E",
              borderColor: "none",
              borderRadius: 20,
              formatter: "{value}" + " kg",
            },
            data: [
              {
                value: this.muscleThis,
                // name: "SCORE",
                name: "Mišićna snaga",
              },
            ],
          },
        ],
      };

      this.myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.muscle {
  width: 400px;
  height: 220px;
  overflow: hidden;
  #mainM {
    width: 100%;
    height: 400px;
  }
}
.muscle.modal {
  width: 550px;
  height: 320px;
  overflow: hidden;
  #mainM {
    width: 550px;
    height: 550px;
  }
}
</style>
