<template>
  <teleport to="#modern-modal">
    <div class="modern-modal modal" :id="getUniqueKey" ref="modal-parent">
      <slot name="overlay">
        <transition name="fade">
          <div
            class="overlay"
            v-if="isOverlayActive"
            @click="onClickOverlay"
          ></div>
        </transition>
      </slot>
      <slot name="shell">
        <transition name="scale-fade" @after-leave="onLeaveAnimation">
          <div
            class="shell"
            :class="{ resize: canResizeShell }"
            :style="formStyle"
            v-if="isLoadedShell"
          >
            <slot name="form">
              <div class="layer" @load-modal-form="onLoadedForm">
                <slot name="header">
                  <header class="header">
                    <span class="title">
                      <div class="text">{{ completeConfig.title }}</div>
                    </span>
                    <div
                      class="close-btn"
                      v-if="completeConfig.isShowCloseBtn !== false"
                      @click="onClickClose"
                    >
                      <svg
                        class="close-icon"
                        style="cursor: pointer"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.00009 9.41431L13.6709 15.0851L15.0851 13.6709L9.41431 8.00009L15.0851 2.32925L13.6709 0.915039L8.00009 6.58588L2.32925 0.915039L0.915039 2.32925L6.58588 8.00009L0.915039 13.6709L2.32925 15.0851L8.00009 9.41431Z"
                          fill="#575758"
                        ></path>
                      </svg>
                    </div>
                  </header>
                </slot>
                <slot name="content"
                  ><span>Content is rendered using slot "#content"</span></slot
                >
              </div>
            </slot>
          </div>
        </transition>
      </slot>
    </div>
  </teleport>
</template>

<script>
// 2022-2 [LIGHT VERSION] - Without focuser
export default {
  name: "ModernModal",
  inheritAttrs: false,
  emits: ["close-modal"],
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    formStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOverlayActive: false,
      isLoadedShell: false,
      isModalBusy: true,
      endQueueData: null,
      endTimeout: null,
      isAddedFocusEvents: false,
      tabHandlerTimeout: null,
    };
  },
  computed: {
    completeConfig() {
      return {
        title: "Modal",
        isShowCloseBtn: true,
        isOverlayClosable: true,
        canResize: false,
        handleEscClose: true,
        handleTabCycle: true, // Sometimes unstable
        ...this.config,
      };
    },
    canResizeShell() {
      return this.completeConfig.canResize;
    },
    getUniqueKey() {
      const randomUuid = crypto.randomUUID?.();
      const randomMath = `asd-${Math.random() * 10000}`;
      return randomUuid || randomMath;
    },
  },
  watch: {
    isModalBusy(val) {
      if (!val) {
        // Used to invoke event after transition
        this.$emit("close-modal", this.endQueueData);
        this.endQueueData = null;
      }
    },
  },
  mounted() {
    this.isOverlayActive = true;
    this.isLoadedShell = true;

    document.body.addEventListener("keyup", this.handleKeyUp);
    // document.body.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    document.body.removeEventListener("keyup", this.handleKeyUp);
    // document.body.removeEventListener("keydown", this.handleKeyDown);
    clearTimeout(this.endTimeout);
    clearTimeout(this.tabHandlerTimeout);
  },
  methods: {
    onClickClose(evt) {
      this.isLoadedShell = false; // This is to trigger transition
      this.isOverlayActive = false;
      this.endQueueData = { evt };
      this.endTimeout = setTimeout(() => {
        console.error(
          "Modal instance not cleared, this will block application. [use '@close-modal' event]"
        );
      }, 2000);
    },
    onClickOverlay(evt) {
      if (this.completeConfig.isOverlayClosable) {
        this.onClickClose(evt);
      }
    },
    onLeaveAnimation() {
      this.isModalBusy = false;
    },
    onLoadedForm() {
      // Invoked from comps to alert modal that it can process listeners
      console.log(">> Form is loaded");
    },
    handleKeyUp(evt) {
      if (this.completeConfig.handleEscClose && evt.key === "Escape") {
        this.onClickClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modern-modal {
  @include modal-comp;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: all 0.3s ease; // Used for transition scaling

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .shell {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 100px;
    color: silver;
    transition: width 0.3s ease, height 0.3s ease;
    z-index: 1;

    .layer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: $modal-back;
      color: $modal-front;
      height: 100%;
      width: 100%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: $modal-front;

        .fa {
          cursor: pointer;
        }
      }
    }

    &.resize {
      resize: both;
      overflow: auto;
    }
  }

  @include fadeTransition;
  @include scaleFadeTransition(0.2s, 0.8);
}
</style>
<style lang="scss">
.modern-modal {
  // Styling for inner content
  .content-inner-pre {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .shell {
    background: white;
  }
}
</style>
