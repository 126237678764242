<template>
  <div class="modal-confirm-dialog">
    <div class="section">
      <div class="text">{{ text }}</div>
    </div>
    <div class="action">
      <button class="button-confirm curved red" @click="onClickConfirm">
        Potvrdi
      </button>
      <button class="button-cancel curved" @click="onClickCancel">
        Odustani
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  methods: {
    onClickConfirm() {
      this.$emit("confirm-action");
      this.$emit("close-modal");
    },
    onClickCancel() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-confirm-dialog {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;

  .section {
    height: 100%;
    display: flex;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    button {
      padding: 8px 22px;
    }
  }
}
</style>
<!-- <style lang="scss">
.modal-confirm {
  display: flex;
  flex-direction: column;
}
</style> -->
