<template>
  <div id="nav-bar" :class="{ dark: isDarkTheme }">
    <modern-modal
      :config="{ title: 'Odjava' }"
      :form-style="{ width: '330px', height: '200px' }"
      v-if="isModalVisible"
      @close-modal="isModalVisible = false"
    >
      <template #content>
        <modal-confirm-action
          text="Da li ste sigurni da hoćete da se izlogujete?"
          @confirm-action="onClickConfirmSignout"
          @close-modal="isModalVisible = false"
        ></modal-confirm-action>
      </template>
    </modern-modal>
    <div class="content-section">
      <div class="left-side">
        <div class="patient-info" v-if="hasPatientInfo">
          <label class="full-name">
            <span class="title">Ime i prezime</span>
            <span class="reg-text" :title="fullName">{{ fullName }}</span>
          </label>
          <!-- <label class="report-num">
              <span class="title">Br. kartona</span>
              <span class="reg-text" :title="reportNumber">{{ reportNumber }}</span>
            </label> -->
          <label class="report-date">
            <span class="title">Datum izveštaja</span>
            <span class="reg-text small-letter" :title="reportDate">{{ reportDate }}</span>
          </label>
          <label class="date-birth">
            <span class="title">Datum rođenja</span>
            <span class="reg-text small-letter" :title="dateBirth">{{ dateBirth }}</span>
          </label>
        </div>
      </div>
      <div class="center">
        <img
          src="@/assets/image/blackBGlogo.svg"
          alt="Vizim logo dark"
          v-if="isDarkTheme"
        />
        <img
          src="@/assets/image/whiteBGlogo.svg"
          alt="Vizim logo"
          v-if="!isDarkTheme"
        />
      </div>
      <div class="right-side">
        <!-- <label
          class="dark-theme-label"
          for="dark-theme-check"
          v-if="hasDarkToggle"
        >
          <span class="reg-text">Dark</span>
          <input
            id="dark-theme-check"
            type="checkbox"
            true-value="1"
            false-value="0"
            :checked="isDarkTheme"
            @change="$emit('change-theme', $event)"
          />
        </label> -->
        <div class="buttons">
          <pdf-element2 v-if="hasExportPdf" class="exportBtn"> </pdf-element2>
          <button
            class="logout"
            :class="{ gray: !isDarkTheme, 'gray-trans': isDarkTheme }"
            v-if="hasLogout"
            @click="onClickSignout"
          >
            <img src="@/assets/image/logout.svg" alt="" />
            <span class="reg-text">Odjava</span>
          </button>
          <!-- <button
            class="back-btn pages"
            @click="onClickBack"
            v-if="pageNum !== 1"
          >
            <span><img src="@/assets/css/chart/arrow.svg" alt="" /></span
            ><span>Nazad</span>
          </button>
          <button
            class="back-btn pages"
            @click="onClickNext"
            v-if="pageNum === 1"
          >
            <span><img src="@/assets/css/chart/arrow.svg" alt="" /></span
            ><span>Napred</span>
          </button>
          <button
            class="back-btn done"
            @click="onClickEnd"
            v-if="pageNum !== totalPages"
          >
            <span>Završi</span>
          </button> -->
        </div>
      </div>
    </div>
    <div class="bottom-line"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "NavBar",
  props: {
    isDarkTheme: Boolean,
    hasPatientInfo: Boolean,
    hasExportPdf: Boolean,
    hasLogout: Boolean,
    // hasDarkToggle: Boolean,
    fullName: {
      type: String,
      default: "Marko Markovic",
    },
    reportDate: {
      type: String,
      default: "04. MAR 2022.",
    },
    reportNumber: {
      type: String,
      default: "885D9882LK",
    },
    dateBirth: {
      type: String,
      default: "21. NOV 1971.",
    },
    pageNum: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    isFirstPage() {
      return this.totalPages === this.pageNum;
    },
    patientData() {
      return this.$store.getters.patientData;
    },
  },
  methods: {
    ...mapMutations(["clearPatientData"]),
    onClickSignout() {
      this.isModalVisible = true;
    },
    onClickConfirmSignout() {
      this.isModalVisible = false;
      this.signoutUser();
    },
    signoutUser() {
      sessionStorage.removeItem("sid"); // TODO Phase 2
      sessionStorage.removeItem("emuSid");
      this.clearPatientData();
      this.$router.replace({
        name: "LoginPage",
      });
      // this.$emit("sign-out");
    },
  },
};
</script>
<style lang="scss" scoped>
#nav-bar {
  $background-color: transparent;

  background: $background-color;
  height: 105px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0;
  font-family: "Archivo";
  user-select: none;

  .content-section {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    > * {
      flex: 1;
      display: flex;
    }
  }

  .bottom-line {
    min-height: 1px;
    width: 100%;
    background: #d3d3d3;
  }

  .left-side {
    display: flex;
  }

  .patient-info {
    display: grid;
    grid-template-columns: repeat(3, 150px);
    grid-template-rows: 1fr;
    gap: 5px;

    label {
      min-width: 150px;
    }
  }

  .center {
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      $size: 50px;
      height: $size;
      // height: 100%;
      pointer-events: none;
      max-height: $size;
    }
  }

  .right-side {
    justify-content: flex-end;

    .pages {
      background-color: Transparent;
      border: none;
      cursor: pointer;
      color: #172841;
      overflow: hidden;

      img {
        margin-right: 10px;
      }
    }

    .done {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.03em;
      width: 95px;
      height: 36px;
      border-radius: 4px;
    }

    .dark-theme-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 10px;

      .logout {
        width: 105px;
        height: 35px;
        border-radius: 121px;

        &.gray-trans {
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  label {
    display: flex;
    flex-direction: column;

    .title {
      color: #7e7f82;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 20px;
    }
  }

  .reg-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .small-letter{
    text-transform:lowercase;
  }

  &.dark {
    label {
      .title {
        color: #ffffffcc;
      }
    }

    .reg-text {
      color: white;
    }

    .bottom-line {
      background: #ffffff1a;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 0 65px 0;

    .right-side {
      .buttons {
        .logout {
          width: 125px;
          height: 44px;
        }
      }
    }
  }
}
</style>
