<template>
  <span v-html="getNumber(currentValue)"> </span>
</template>

<script>
export default {
  name: "anime-animate",
  //['start','end','animlength','value', 'expMarket1'],
  props: {
    start: {
      required: false,
    },
    end: {
      required: false,
      type: Number,
    },
    animlength: {
      required: false,
    },
  },
  data: function () {
    return {
      currentValue: 0,
      totalSteps: 1,
      STEP_TIME: 20,
      incrementStep: 1,
    };
  },
  mounted() {
    this.STEP_TIME = Math.ceil(this.animlength / this.end);
    // console.log(
    //   "this.STEP_TIME = this.animlength / this.end ---------" +
    //     this.STEP_TIME * this.end
    // );
    this.animateStep();
    // console.log(this.STEP_TIME + "this.stepTime");
  },
  methods: {
    animateStep() {
      //   console.log(this.incrementStep + "this.incrementStep");
      this.currentValue += 1;

      this.incrementStep += this.STEP_TIME;
      if (
        this.currentValue === this.end ||
        this.incrementStep >= this.animlength
      ) {
        this.currentValue = this.end;
        return;
      }

      setTimeout(() => {
        this.animateStep();
      }, this.STEP_TIME);
    },
    getNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>

<style lang="scss"></style>
