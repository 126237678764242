<template>
  <div id="bottom-nav" :class="{ dark: isDarkTheme }">
    <modern-modal
      :config="{ title: 'Završi anketu' }"
      :form-style="{ width: '330px', height: '200px' }"
      v-if="isModalVisible"
      @close-modal="isModalVisible = false"
    >
      <template #content>
        <modal-confirm-action
          text="Da li ste sigurni da hoćete da završite anketu?"
          @confirm-action="onConfirmAction"
          @close-modal="isModalVisible = false"
        ></modal-confirm-action>
      </template>
    </modern-modal>
    <div class="bottom-left"></div>
    <div class="bottom-center-text">
      <span>Created by</span>&nbsp;
      <strong>
        <a href="https://enonsolutions.com/" target="_blank">ENON Solutions</a>
      </strong>
      <span>&nbsp;-&nbsp;</span>
      <span>
        powered by&nbsp;<strong>
          <a href="https://marsengine.net/" target="_blank">MARS Engine</a>
        </strong>
      </span>
      .
    </div>
    <div class="buttons">
      <button
        class="curved-btn"
        :class="{ gray: !isDarkTheme, 'gray-trans': isDarkTheme }"
        @click="onClickBack"
      >
        <!-- v-if="pageNum !== 1" -->
        <img src="@/assets/image/formArrow.svg" alt="" />
        <span class="reg-text">Nazad na anketu</span>
      </button>
      <button
        class="curved-btn"
        :class="{ 'gray-trans': !isDarkTheme, white: isDarkTheme }"
        @click="onClickFinish"
      >
        <!-- v-if="pageNum !== totalPages" -->
        <span class="bl-text">Završi</span>
        <img
          src="@/assets/image/formArrow.svg"
          alt=""
          style="transform: rotate(180deg)"
        />
      </button>
      <!-- <button
            class="back-btn pages"
            @click="onClickNext"
            v-if="pageNum === 1"
          >
            <span><img src="@/assets/css/chart/arrow.svg" alt="" /></span
            ><span>Napred</span>
          </button>-->
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "NavBar",
  props: {
    isDarkTheme: Boolean,
    pageNum: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isModalVisible: false,
    };
  },
  computed: {
    isFirstPage() {
      return this.totalPages === this.pageNum;
    },
    patientData() {
      return this.$store.getters.patientData;
    },
  },
  methods: {
    ...mapMutations(["clearPatientData"]),
    onClickBack() {
      this.$router.push({ name: "FormPage" });
    },
    onClickFinish() {
      this.isModalVisible = true;
    },
    onConfirmAction() {
      this.isModalVisible = false;
      this.finishForm();
    },
    finishForm() {
      this.clearPatientData();
      this.$router.push({ name: "FormPage" });
    },
  },
};
</script>
<style lang="scss" scoped>
#bottom-nav {
  $background-color: transparent;

  background: $background-color;
  height: 105px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // flex-direction: column;
  align-items: center;
  padding: 0 20px 0;
  font-family: "Archivo";
  user-select: none;

  > * {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  .center {
    justify-content: center;
    align-items: center;
    height: 100%;

    img {
      $size: 50px;
      height: $size;
      // height: 100%;
      pointer-events: none;
      max-height: $size;
    }
  }

  .bottom-center-text {
    font-size: 13px;
    color: white;

    a {
      color: white;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    gap: 10px;

    .curved-btn {
      height: 44px;
      border-radius: 121px;
      padding: 0 22px;

      &.gray-trans {
        img {
          filter: brightness(0) invert(1);
        }
      }

      &.white {
        img {
          filter: brightness(1) invert(1);
        }
      }
    }
  }

  .reg-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
  }

  .bl-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #22242a;
  }

  &.dark {
    label {
      .title {
        color: #ffffffcc;
      }
    }

    .reg-text {
      color: white;
    }
  }
}
</style>
