import { useToast } from "vue-toastification";
const toastInstance = useToast();

import ToastComp from "@/components/Toast/ToastComp.vue";

export default {
  components: {
    ToastComp,
  },
  methods: {
    openToastCore(msg, type, opt) {
      toastInstance.success(
        {
          component: ToastComp,
          props: {
            type: type,
            msg,
            ...opt,
          },
        },
        opt
      );
    },
    openToastSuccess(msg, opt = {}) {
      this.openToastCore(msg, "success", opt);
    },
    openToastError(msg, opt = {}) {
      this.openToastCore(msg, "error", opt);
    },
  },
};
