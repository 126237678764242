<template>
  <div id="app">
    <teleport to="head title">{{ appMetaTitle }}</teleport>
    <!-- <div class="testing-links">
      <div class="links">
        <router-link
          v-for="(link, index) in links"
          :key="index"
          :to="{ name: link.routeName }"
          ><span>{{ link.label }}</span>
        </router-link>
      </div>
      <span></span>
    </div> -->
    <router-view v-slot="{ Component, route }">
      <wrapper-route
        :route-meta="route.meta"
        :theme-colors="appThemesArr"
        :is-dark-theme="$route.meta.hasDarkTheme"
        @change-theme="onChangeTheme"
      >
        <component
          :is="Component"
          :theme-colors="appThemesArr"
          :isDarkTheme="$route.meta.hasDarkTheme"
        />
      </wrapper-route>
    </router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      appMetaTitle: "Vizim Vitality",
      // appThemeIndex: 0,
      appThemesArr: ["white", "#23242b"],
      links: [
        {
          label: "Login page",
          routeName: "LoginPage",
        },
        {
          label: "FormPage",
          routeName: "FormPage",
        },
        {
          label: "ChartPage",
          routeName: "ChartPage",
        },
        // {
        //   label: "Register page",
        //   routeName: "RegisterPage",
        // },
      ],
    };
  },
  computed: {
    // appBackTheme() {
    //   return this.appThemesArr[this.appThemeIndex];
    // },
  },
  methods: {
    async onChangeTheme(evt) {
      //   this.appThemeIndex = evt.target.checked ? 1 : 0;
      //   await this.$nextTick();
    },
  },
};
</script>

<style lang="scss">
@import url("@/assets/css/vars.css");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Bad+Script&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Archivo:wght@500&display=swap");

@font-face {
  font-family: "Archivo Extra Bold";
  font-style: normal;
  src: url(./assets/fonts/Archivo-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Archivo Black";
  font-style: normal;
  src: url(./assets/fonts/Archivo-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Archivo Bold";
  font-style: normal;
  src: url(./assets/fonts/Archivo-Bold.ttf) format("truetype");
}

html {
  box-sizing: border-box;
  height: 100%;
  font-family: "Archivo", "Times new Roman", sans-serif;
  letter-spacing: 0.3px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  height: 100%;
  display: flex;

  @media screen and (min-width: 768px) {
    overflow-x: unset;
  }

  .testing-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0 20px;

    .links {
      display: flex;
      gap: 20px;
    }
  }
}

#app {
  // width: 100%; // Test this
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
