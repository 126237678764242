<template>
  <div class="testPdfWrapper">
    <div class="first-page">
      <div class="first-part-one">
        <div class="img-date-rings">
          <div class="pdf-image">
            <img src="../assets/image/pdfImgj2.jpg" alt="" />
          </div>
          <div class="date-rings">
            <div class="date">
              <div class="patient-info" v-if="!hasPatientInfo">
                <label class="full-name">
                  <span class="title">Ime i prezime</span>
                  <span class="reg-text"
                    >{{ patientData.firstName }}
                    {{ patientData.lastName }}</span
                  ><br />
                </label>
                <!-- <label class="report-num">
                              <span class="title">Br. kartona</span>
                              <span class="reg-text">{{ reportNumber }}</span>
                              </label> -->
                <label class="date-birth">
                  <span class="title">Datum rođenja</span>
                  <span class="reg-text">{{ patientData.dateOfBirth }}.</span>
                  <br />
                </label>
                <label class="report-date">
                  <span class="title">Datum izveštaja</span>
                  <span class="reg-text small-letter">{{ reportDate }}</span>
                </label>
              </div>
            </div>
            <div class="rings">
              <div class="line-between-date-and-rings"></div>
              <FullRing
                ref="mira"
                :isDarkTheme="isDarkTheme"
                :setValue="patientData.height"
                :setColor="['#34D67E', '#22C59D']"
                title="Visina"
                unit="cm"
                id="visina"
                :animationDuration="animationDuration"
                class="ringsPdf"
              />
              <FullRing
                :isDarkTheme="isDarkTheme"
                :setValue="patientData.weight"
                :setColor="['#F26A58', '#E9415F']"
                title="Težina"
                unit="kg"
                id="tezina"
                :animationDuration="animationDuration"
                class="ringsPdf"
              />
            </div>
          </div>
        </div>

        <div class="bmi">
          <div class="bmi-wt">
            <BimChart
              :isDarkTheme="isDarkTheme"
              :bmi="patientData.bmi"
              :animationDuration="animationDuration"
              :isModal="true"
            />
          </div>
          <div class="bmi-text">
            <p>
              BMI predstavlja odnos telesne težine i visine. Vrednost BMI izvan
              normalnih granica ukazuje na postojanje zdravstvenih rizika kojima
              ste izloženi.
            </p>
          </div>
        </div>
        <div class="ms">
          <div class="ms-wt">
            <muscleStrength
              :isDarkTheme="isDarkTheme"
              :muscle="patientData.muscleStrength"
              :gender="patientData.gender"
              :age="age"
              :animationDuration="animationDuration"
            />
          </div>
          <div class="ms-text">
            <p>
              Mišićna snaga je važan faktor ljudske mobilnosti. Mišići pomažu
              postizanju bolje stabilnosti, ravnoteže i fleksibilnosti,
              smanjujući mogućnost padova i nastanka povreda.
            </p>
          </div>
        </div>
      </div>
      <div class="first-part-two">
        <div class="Bp">
          <div class="bp">
            <BloodPressure
              @click="showModal(4)"
              :systolicBP="parseInt(patientData.bps)"
              :diastolicBP="parseInt(patientData.bpd)"
              :isAnimated="false"
              :isDarkTheme="false"
            ></BloodPressure>
          </div>
          <div class="bp-text">
            <p>
              Normalan krvni pritisak pokazuje kojom silom srce pumpa krv kako
              bi svim ćelijama u organizmu bili dostavljeni kiseonik i hranljive
              materije. Povišen krvni pritisak se naziva „tihim ubicom“, jer
              povećana sila kojom se krv pumpa, oštećuje krvne sudove, a
              vremenom i srce, mozak, bubrege i vid. Jedan je od najčešćih
              uzroka nastanka moždanog i srčanog udara.
            </p>
          </div>
        </div>
        <div class="mental-strength">
          <!-- <FullRing
            :isDarkTheme="isDarkTheme"
            :setValue="patientData.mentalState"
            :setColor="mentalColor"
            title="Mentalno Stanje"
            unit=""
            id="mentalno"
            :animationDuration="animationDuration"
          /> -->
          <mentalState
            :isDarkTheme="false"
            :sleepEff="patientData.mentalState"
            title="Mentalno stanje"
            :animationDuration="animationDuration"
          />
          <div class="mental-text">
            <p>
              Dobro mentalno zdravlje ima izuzetan uticaj na psihološko,
              emocionalno i socijalno blagostanje.
            </p>
          </div>
        </div>
      </div>
      <div class="first-part-three">
        <div class="percentage">
          <div class="percentage-wt">
            <PercentageFat
              :isDarkTheme="isDarkTheme"
              :setColor="['#37C78F', '#E9CD85', '#CD1939']"
              :fat="patientData.bfp"
              :gender="patientData.gender"
              :age="age"
              :animationDuration="animationDuration"
            />
          </div>
          <div class="perc-fat-text">
            <p>
              Pokazatelj količine telesnih masti u organizmu. Povećan procenat
              telesnih masti je direktno povezan sa većom mogućnošću pojave
              raznih bolesti kao što su: hipertenzija, moždani udar, bolesti
              srca i dijabetes tipa 2.
            </p>
          </div>
        </div>
        <div class="efficiency-sleep">
          <div class="efficiency-sleep-wt">
            <EfficiencySleep
              :isDarkTheme="isDarkTheme"
              :sleepEff="patientData.sleepEfficiency"
              :animationDuration="animationDuration"
            />
          </div>
          <div class="efficiency-text">
            <p>
              Procenat koji pokazuje koliko vremena osoba spava u odnosu na
              vreme koje provodi u postelji. Efikasnost spavanja koja je iznad
              85% ukazuje na dobar kvalitet spavanja.
            </p>
          </div>
        </div>
        <div class="average-sleep">
          <div class="average-sleep-wt">
            <AverageDream
              :isDarkTheme="isDarkTheme"
              :averageDream="{
                value: patientData.sleepDuration,
                txt: 'Prosečan san',
              }"
              :animationDuration="animationDuration"
              class="averageDreamPdf"
            />
          </div>
          <div class="average-sleep-text">
            <p>
              Spavanje pomaže dobrom funkcionisanju, poboljšava pamćenje,
              učenje, donošenje odluka, kreativnost, pomaže održavanju telesne
              težine, poboljšava sportsku sposobnost i smanjuje mogućnost
              nastanka bolesti srca. Svako smanjenje dužine spavanja ispod 7
              sati značajno povećava zdravstvene rizike kojima ste izloženi.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="second-page">
      <div class="second-part-one">
        <div class="lp">
          <div class="lp-wt">
            <wrappLineCharts
              :lipidProfile="lipidProfile"
              :isDarkTheme="isDarkTheme"
              :animationDuration="animationDuration"
            />
          </div>
          <div class="lp-text">
            <p>
              <span class="bold-text">Holesterol</span> je tip lipida koji se
              prenosi u telu putem krvi. Stvara se u ljudskom organizmu, ali ga
              unosimo i hranom, isključivo onom životinjskog porekla. Holesterol
              je potreban svim ćelijama u organizmu, a neophodan je i za
              stvaranje nekih hormona i vitamina D. Međutim, povećana
              koncentracija holesterola može dovesti do bolesti srca, odnosno
              koronarne bolesti.
            </p>
            <p>
              <span class="bold-text"> HDL holesterol </span> odnosno holesterol
              visoke gustine se smatra „dobrim holesterolom“. Njegova uloga je
              da iz arterija uklanja druge vrste holesterola, kao što je LDL, i
              transportuje ih u jetru, odakle će biti potpuno uklonjeni. HDL
              holesterol ima ulogu „nosača“ koji transportuje ostale vrste
              holesterola koje bi mogle da nam naude.
            </p>
            <p>
              <span class="bold-text"> LDL holesterol </span> ili holesterol
              niske gustine predstavlja „loš holesterol“. On se zadržava na
              zidovima krvnih sudova čineći ih užim u procesu koji nazivamo
              ateroskleroza i može zaustaviti protok krvi, što dovodi do srčanog
              i moždanog udara. Njegov nivo povećava konzumiranje namirnica koje
              su bogate zasićenim mastima kao što su meso, mleko, sir i buter,
              kao i onih koje imaju trans-masti, a koje nalazimo u brzoj i
              prženoj hrani, keksu.
            </p>
            <p>
              <span class="bold-text">Trigliceridi</span> su masti iz hrane koje
              se transportuju putem krvi. Višak unete hrane ljudski organizam
              pretvara u trigliceride i deponuje u masnim ćelijama. Kao i
              holesterol, trigliceridi pripadaju lipidima, ali su trigliceridi
              prave masti, a holesterol to nije. Povećan nivo triglicerida je
              povezan sa većom verovatnoćom nastanka bolesti srca.
            </p>
          </div>
        </div>
      </div>
      <div class="second-part-two">
        <div class="wrappItem aaa">
          <div class="line-cube-first">
            <div class="line-cube-wt">
              <LineCube
                :isDarkTheme="isDarkTheme"
                :setValue="patientData.estimatedAgeMDNA"
                :setColor="'green'"
                title="Procenjena starost mDNK"
                txt="starost"
                unit=""
                :animationDuration="animationDuration"
              />
            </div>
            <div class="line-cube-text">
              <p>
                Ovaj pokazatelj predstavlja visoko preciznu procenu stepena
                metilacije DNK koja ukazuje na nivo individualne ćelijske
                starosti.
              </p>
            </div>
          </div>
          <div class="line-cube-second">
            <div class="line-cube-wt">
              <LineCube
                :isDarkTheme="isDarkTheme"
                :setValue="patientData.estimatedAgePtypic"
                :setColor="'orange'"
                title="Procenjena Ptypic Age"
                txt="starost"
                unit=""
                :animationDuration="animationDuration"
              />
            </div>
            <div class="line-cube-text">
              <p>
                Ovaj pokazatelj je snažno povezan sa individualnim razlikama u
                zdravlju iz bilo kog razloga, smanjenju funkcionalnosti i nivoa
                kognitivnih performansi
              </p>
            </div>
          </div>

          <div class="line-cube-third">
            <LineCube
              :isDarkTheme="isDarkTheme"
              :setValue="age"
              :setColor="'red'"
              title="Starost"
              txt="starost"
              unit=""
              :animationDuration="animationDuration"
            />
          </div>
        </div>
      </div>
      <div class="second-part-three">
        <div class="second-part-three-first">
          <div class="das">
            <div class="depresion">
              <div class="depresion-wt">
                <RygRing2
                  :isDarkTheme="isDarkTheme"
                  :setValue="patientData.depression"
                  :max="depression[depression.length - 1].h"
                  recommended=""
                  :setColor="colorDepression"
                  title="Depresija"
                  unit=""
                  id="depresija"
                  :animationDuration="animationDuration"
                />
              </div>
              <div class="depresion-text">
                <p>
                  Depresija ima vrlo negativne posledice po zdravlje. Osobe sa
                  dugotrajnom, nelečenom depresijom imaju mnogo veći rizik za
                  pojavu srčanih oboljenja, poremećaja spavanja, slabljenje
                  imunog sistema, dijabetesa, moždanog udara, Alchajmerove
                  bolesti i drugih oboljenja.
                </p>
              </div>
            </div>
            <div class="anxiety">
              <div class="anxiety-wt">
                <RygRing2
                  :isDarkTheme="isDarkTheme"
                  :setValue="patientData.nervousness"
                  :max="nervous[nervous.length - 1].h"
                  recommended=""
                  :setColor="colorNervous"
                  title="Anksioznost"
                  id="nervoza"
                  :animationDuration="animationDuration"
                />
              </div>
              <div class="anxiety-text">
                <p>
                  Određeni stepen osećaja nervoze može da bude pozitivan jer je
                  on neophodan za reakciju „bori se ili beži“ koja nastaje kada
                  smo ugroženi. Međutim, kada postane svakodnevni osećaj sa
                  kojim se ljudi suočavaju, ima štetne posledice po zdravlje.
                </p>
              </div>
            </div>
            <div class="stress">
              <div class="stress-wt">
                <RygRing2
                  :isDarkTheme="isDarkTheme"
                  :setValue="patientData.stress"
                  :max="stress[stress.length - 1].h"
                  recommended=""
                  :setColor="colorStress"
                  title="Stres"
                  id="stres"
                  :animationDuration="animationDuration"
                />
              </div>
              <div class="stress-text">
                <p>
                  Dugotrajna izloženost stresu može biti okidač za nastanak
                  raznih bolesti i ima jako negativan uticaj na zdravlje.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="second-part-three-second">
          <div class="first-item">
            <div class="bazal-wt">
              <FullRing
                :isDarkTheme="isDarkTheme"
                :setValue="patientData.bmr"
                :setColor="['#F39D4E', '#F34E4E']"
                title="Bazalni metabolizam"
                unit=""
                id="bazalni"
                :animationDuration="animationDuration"
              />
            </div>
            <div class="bazal-text">
              <p>
                Pokazatelj ukupnog broja kalorija neophodnog za pravilno
                funkcionisanje organizma. Predstavlja važan podatak u
                izračunavanju ukupne energetske potrošnje i izuzetno je bitan za
                izradu programa za dostizanje i održavanje idealne telesne
                težine.
              </p>
            </div>
          </div>
          <div class="second-item">
            <div class="physical-activity-wt">
              <PhysicalActivity
                :isDarkTheme="isDarkTheme"
                :setColor="['#37C78F', '#E9CD85', '#CD1939']"
                :activity="patientData.physicalActivity"
                :animationDuration="animationDuration"
              />
            </div>
            <div class="physical-activity-text">
              <p>
                Fizička aktivnost ima izuzetne zdravstvene koristi i pomaže u
                prevenciji kardiovaskularnih bolesti, dijabetesa i nekih vrsta
                karcinoma. Pored toga smanjuje simptome depresije, anksioznosti
                i poboljšava opšte fizičko i mentalno blagostanje. Zdravim
                odraslim osobama se preporučuje od 150 do 300 minuta fizičke
                aktivnosti umerenog intenziteta nedeljno.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nDate } from "../assets/js/dateHelper.ts";
import { mapGetters } from "vuex";
export default {
  props: {
    isDarkTheme: Boolean,
    themeColors: Array,
    animationDuration: Number,
    hasPatientInfo: Boolean,
    fullName: {
      type: String,
      default: "Marko Markovic",
    },

    dateBirth: {
      type: String,
      default: "21. NOV 1971.",
    },
  },
  created() {
    this.age = this.getAge();
    this.setLipidProfile();
    this.calcColorMentalState();
    // this.colorDepression = this.calcColorDepNerStr2(
    //   this.patientData.depression,
    //   this.depression
    // );
    this.colorDepression = [
      [0.214, "#1BC691"],
      [0.309, "#FDA452"],
      [0.476, "#F46B7B"],
      [0.642, "#e22545"],
      [1, "#9e2828"],
    ];
    // this.colorNervous = this.calcColorDepNerStr2(
    //   this.patientData.nervousness,
    //   this.nervous
    // );
    this.colorNervous = [
      [0.166, "#1BC691"],
      [0.214, "#FDA452"],
      [0.333, "#F46B7B"],
      [0.452, "#e22545"],
      [1, "#9e2828"],
    ];
    // this.colorStress = this.calcColorDepNerStr2(
    //   this.patientData.stress,
    //   this.stress
    // );
    this.colorStress = [
      [0.333, "#1BC691"],
      [0.428, "#FDA452"],
      [0.595, "#F46B7B"],
      [0.785, "#e22545"],
      [1, "#9e2828"],
    ];
    this.reportDate = this.getReportDate();
  },
  data() {
    return {
      reportDate: null,
      percentageFatData: 27,
      gender: "female", // male   female // patientData.gender
      age: 27,
      muscleStrength: 25.5,
      setChart: false,
      lipidProfile: [
        {
          name: "Holesterol",
          recommended: 5.2,
          value: 0,
        },
        {
          name: "HDL holesterol",
          recommended: 1.0,
          value: 0,
        },
        {
          name: "LDL holesterol",
          recommended: 4.1,
          value: 0,
        },
        {
          name: "Trigliceridi",
          recommended: 1.7,
          value: 0,
        },
      ],
      mentalColor: ["#34D67E", "#22C59D"], // default value - set new when mounted component
      mental: [
        { l: 0, h: 53, color: ["#F34E4E", "#E33955"] }, // red
        { l: 54, h: 70, color: ["#F2E679", "#34D67E"] }, // green , yellow
        { l: 71, h: 100, color: ["#34D67E", "#22C59D"] }, // green
      ],
      colorDepression: ["#37C78F", "#E9CD85", "#CD1939"],
      depression: [
        // depresija
        {
          l: 0,
          h: 9,
          color: [
            [0.31, "#1BC691"],
            [1, "transparent"],
          ],
        }, // normalan nivo
        {
          l: 10,
          h: 13,
          color: [
            [0.31, "#1BC691"],
            [0.448, "#FDA452"],
            [1, "transparent"],
          ],
        }, // nizak intenzitet
        {
          l: 14,
          h: 20,
          color: [
            [0.31, "#1BC691"],
            [0.448, "#FDA452"],
            [0.68, "#E33955"],
            [1, "transparent"],
          ],
        }, // umeren intenzitet
        {
          l: 21,
          h: 27,
          color: [
            [0.31, "#1BC691"],
            [0.448, "#FDA452"],
            [0.68, "#E33955"],
            [0.93, "#e22545"],
            [1, "transparent"],
          ],
        }, // jak intenzitet
        {
          l: 28,
          // h: 29,
          h: 42,
          color: [
            [0.31, "#1BC691"],
            [0.448, "#FDA452"],
            [0.68, "#E33955"],
            [0.93, "#e22545"],
            [1, "#9e2828"],
          ],
        }, // izuzetno jak intenzitet
        // Iznad 29 – izuzetno jak intenzitet – tamno crveno
      ],
      colorNervous: ["#37C78F", "#E9CD85", "#CD1939"],
      nervous: [
        // nervoza
        {
          l: 0,
          h: 7,
          color: [
            [0.333, "#1BC691"],
            [1, "transparent"],
          ],
        }, // normalan nivo
        {
          l: 8,
          h: 9,
          color: [
            [0.333, "#1BC691"],
            [0.428, "#FDA452"],
            [1, "transparent"],
          ],
        }, // nizak intenzitet
        {
          l: 10,
          h: 14,
          color: [
            [0.333, "#1BC691"],
            [0.428, "#FDA452"],
            [0.666, "#E33955"],
            [1, "transparent"],
          ],
        }, // umeren intenzitet
        {
          l: 15,
          h: 19,
          color: [
            [0.333, "#1BC691"],
            [0.428, "#FDA452"],
            [0.666, "#E33955"],
            [0.94, "#e22545"],
            [1, "transparent"],
          ],
        }, // jak intenzitet
        {
          l: 20,
          // h: 21,
          h: 42,
          color: [
            [0.333, "#1BC691"],
            [0.428, "#FDA452"],
            [0.666, "#E33955"],
            [0.94, "#e22545"],
            [1, "#9e2828"],
          ],
        }, // izuzetno jak intenzitet
        // Iznad 20 – izuzetno jak intenzitet – tamno crveno
      ],
      colorStress: ["#37C78F", "#E9CD85", "#CD1939"],
      stress: [
        // stres
        {
          l: 0,
          h: 14,
          color: [
            [0.4, "#1BC691"],
            [1, "transparent"],
          ],
        }, // normalan nivo zelena boja
        {
          l: 15,
          h: 18,
          color: [
            [0.4, "#1BC691"],
            [0.514, "#FDA452"],
            [1, "transparent"],
          ],
        }, // nizak intenzitet narandžasta
        {
          l: 19,
          h: 25,
          color: [
            [0.4, "#1BC691"],
            [0.514, "#FDA452"],
            [0.714, "#E33955"],
            [1, "transparent"],
          ],
        }, // umeren intenzitet svetlo crveno
        {
          l: 26,
          h: 33,
          color: [
            [0.4, "#1BC691"],
            [0.514, "#FDA452"],
            [0.714, "#E33955"],
            [0.942, "#e22545"],
            [1, "transparent"],
          ],
        }, // jak intenzitet crveno
        {
          l: 34,
          // h: 35,
          h: 42,
          color: [
            [0.4, "#1BC691"],
            [0.514, "#FDA452"],
            [0.714, "#E33955"],
            [0.942, "#e22545"],
            [1, "#9e2828"],
          ],
        }, // izuzetno jak intenzitet tamno crveno
        //  Iznad 34 – izuzetno jak intenzitet – tamno crveno
      ],
      stressOLD: [
        // stres
        { l: 0, h: 14, color: ["#34D67E", "#E9CD85", "#22C59D"] }, // normalan nivo zelena boja
        { l: 15, h: 18, color: ["#F39D4E", "#E9CD85", "#F34E4E"] }, // nizak intenzitet narandžasta
        { l: 19, h: 25, color: ["#F26A58", "#F39D4E", "#E9415F"] }, // umeren intenzitet svetlo crveno
        { l: 26, h: 33, color: ["#F34E4E", "#E33955", "#E33955"] }, // jak intenzitet crveno
        { l: 34, h: 35, color: ["#E33955", "#E33955", "#9e2828"] }, // izuzetno jak intenzitet tamno crveno
        //  Iznad 34 – izuzetno jak intenzitet – tamno crveno
      ],
    };
  },
  methods: {
    getAge() {
      var today = new Date();
      let arr = this.patientData.dateOfBirth.split(".").reverse().join("/");
      var birthDate = new Date(arr);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    setLipidProfile() {
      this.lipidProfile[0].value = this.patientData.cholesterol;
      this.lipidProfile[1].value = this.patientData.cholesterolHDL;
      this.lipidProfile[2].value = this.patientData.cholesterolLDL;
      this.lipidProfile[3].value = this.patientData.triglycerides;
    },
    calcColorMentalState() {
      for (let i = 0; i < this.mental.length; i++) {
        if (
          this.patientData.mentalState >= this.mental[i].l &&
          this.patientData.mentalState <= this.mental[i].h
        ) {
          this.mentalColor = this.mental[i].color;
          return;
        }
      }
    },
    calcColorDepNerStr(val, arr) {
      if (val > arr[arr.length - 1].h) {
        return ["#E33955", "#E33955", "#9e2828"]; // dark red
      }
      for (let i = 0; i < arr.length; i++) {
        if (val >= arr[i].l && val <= arr[i].h) {
          console.log("this.colorDepression  forrr if");
          console.log(arr[i].color);
          return arr[i].color;
        }
      }
    },
    calcColorDepNerStr2(val, arr) {
      if (val >= arr[arr.length - 1].h) {
        return arr[arr.length - 1].color;
      }
      let percent = val / arr[arr.length - 1].h; //* 100;
      for (let i = 0; i < arr.length; i++) {
        if (val >= arr[i].l && val <= arr[i].h) {
          console.log("this.colorDepression  forrr if");
          arr[i].color[i][0] = percent;
          console.log(arr[i].color);
          return arr[i].color;
        }
      }
    },
    getReportDate() {
      const date = new nDate();
      return date.formatDate("DD. MMM YYYY.");
    },
  },
  computed: {
    ...mapGetters(["patientData"]),
  },
};
</script>

<style scoped>
p {
  font-style: normal;
  font-size: 15px;
  line-height: 15px;
  color: #4f5054;
  padding: 15px;
  background: #f7f7f8;
  word-spacing: 1px;
}
.title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 10px;
  color: #494c54;
  margin-bottom: 12px;
}
.reg-text {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  color: #22242a;
}
.small-letter {
  text-transform: lowercase;
}
.line-between-date-and-rings {
  min-height: 1px;
  width: 0.5%;
  background: #dddddd;
  margin: 0px 14px;
}
.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.testPdfWrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-family: "Archivo", "Times new roman", sans-serif;
  font-size: 14px;
  /* font-weight: 100; */

  /* padding: 100px; */
}
.first-page {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
.second-page {
  display: flex;
  flex-direction: column;
  padding-top: 68px;
  margin-left: 10px;
}
.date-rings {
  display: flex;
  flex-direction: row;
}
.full-name,
.date-birth,
.report-date {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.rings {
  display: flex;
  flex-direction: row;
}

.ms-text {
  inline-size: 520px;
  overflow-wrap: break-word;
  /* margin: 0 auto; */
}
.bmi-text {
  inline-size: 520px;
  overflow-wrap: break-word;
  /* margin: 0 auto; */
}

.mental-text {
  inline-size: 149px;
  overflow-wrap: break-word;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.bp-text {
  inline-size: 337px;
  overflow-wrap: break-word;
  margin: 0 auto;
  padding-top: 50px;
}
.perc-fat-text,
.efficiency-text {
  inline-size: 377px;
  overflow-wrap: break-word;
  margin: 0 auto;
  padding-top: 20px;
}
.average-sleep-text {
  inline-size: 179px;
  overflow-wrap: break-word;
  margin: 0 auto;
}
.lp-text {
  inline-size: 1027px;
  overflow-wrap: break-word;
  margin-top: 30px;
}
.lp-text p {
  padding: 7px 1px 1px 8px;
}
.line-cube-text {
  inline-size: 365px;
  overflow-wrap: break-word;
  margin: 0 auto;
}
.items-text p {
  inline-size: 278px;
  overflow-wrap: break-word;
}
.mental-strength {
  display: flex;
  flex-direction: row;
}
.average-sleep {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.lp {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px;
}
.aaa {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 20px 50px 20px;
}
.second-part-three {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.dns {
  display: flex;
  flex-direction: column;
}
.dns-items {
  display: flex;
  flex-direction: row;
}
.dns-items p {
  inline-size: 331px;
  overflow-wrap: break-word;
}
.second-part-three-second .first-item {
  display: flex;
  flex-direction: row;
  padding-bottom: 35px;
}
.second-part-three-second .second-item {
  display: flex;
  flex-direction: row;
}
.second-part-three-second .first-item p,
.second-part-three-second .second-item p {
  inline-size: 460px;
  overflow-wrap: break-word;
}
.line-cube-first,
.line-cube-second {
  display: flex;
  flex-direction: row;
}
.line-cube-text {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.depresion,
.anxiety,
.stress {
  display: flex;
  flex-direction: row;
}
.depresion .depresion-text,
.anxiety .anxiety-text,
.stress .stress-text {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: 10px;
  inline-size: 570px;
  overflow-wrap: break-word;
}
.depresion .depresion-text p,
.anxiety .anxiety-text p,
.stress .stress-text p {
  display: flex;
  align-items: center;
  height: 80%;
}
.physical-activity-text {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: 3px;
  inline-size: 495px;
  overflow-wrap: break-word;
  padding-left: 33px;
  margin-top: 17px;
}
.physical-activity-text p {
  display: flex;
  align-items: center;
  height: 170px;
}
.bazal-text {
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: 46px;
  inline-size: 477px;
  overflow-wrap: break-word;
}
.fat {
  margin: 0 auto;
}
.sleep {
  margin: 0 auto;
}
.first-part-one {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
}
.first-part-two {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 20px 80px;
}
.first-part-three {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 20px 0px 80px;
}
.wrapp-line-charts {
  margin-top: 15px;
}
.testPdfWrapper {
  background: white;
}
.resize {
  background: white;
}
/* .pdf-image {
  width: 144px;
  height: 27px;
  background-image: url("../assets/image/imgPdf.svg");
} */
.pdf-image {
  padding-bottom: 35px;
}
.pdf-image img {
  /* width: 200px;
  height: 35.15px; */
  width: 251px;
  height: 50px;
}
/* .ringsPdf{
  margin-top: 21px;
  width: 180px;
  height: 180px;
} */
.average-sleep-wt {
  margin-right: 20px;
}
.averageDreamPdf {
  padding-left: 0px;
}
.ms {
  padding-top: 60px;
}
.bmi-wt {
  display: flex;
  justify-content: center;
}
.ms-wt {
  display: flex;
  justify-content: center;
}
.bazal-wt {
  margin-right: 1px;
  padding-left: 20px;
}
.bazal-text p {
  inline-size: 430px;
  display: flex;
  align-items: center;
  height: 80%;
}
.bold-text {
  font-family: "Archivo Bold", "Times new roman", "sans-serif";
  /* font-family: "Archivo-Regular", sans-serif; */
  font-weight: 600;
  font-size: 14px;
}
.line-cube-text p {
  height: 90%;
  display: flex;
  align-items: center;
}
.physical-activity-wt {
  padding-right: 10px;
}
</style>
