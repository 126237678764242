<template>
  <div
    :class="['line-cube', { modal: isModal, white: !isDarkTheme }]"
    v-if="show"
    @click="$emit('clickv')"
  >
    <div
      :class="[
        {
          red: setColor === 'red' && isDarkTheme,
          orange: setColor === 'orange' && isDarkTheme,
          green: setColor === 'green' && isDarkTheme,
          redWbg: setColor === 'red' && !isDarkTheme,
          orangeWbg: setColor === 'orange' && !isDarkTheme,
          greenWbg: setColor === 'green' && !isDarkTheme,
        },
        'color',
      ]"
      :style="{ 'animation-duration': animationDuration / 1000 + 's' }"
    ></div>
    <div class="line" :style="{ background: this.color }"></div>
    <div class="wrapp-data">
      <div class="title" :style="{ color: this.color }">{{ title }}</div>
      <div
        class="value"
        :style="{ color: this.isDarkTheme ? '#fff' : '#2B2B2E' }"
      >
        <!-- <Animate
          :animlength="animationDuration / 2"
          :end="setValue"
          :start="0"
        /> -->
        <animateNum
          :animlength="animationDuration"
          :end="valueThis"
          :start="0"
        />
      </div>
      <div
        class="txt"
        :style="{ color: this.isDarkTheme ? '#ffffffcc' : '#2B2B2E' }"
      >
        {{ txt }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lineCube",
  props: {
    setColor: {},
    setValue: {},
    title: {},
    txt: {},
    critic: {},
    max: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  created() {
    this.valueThis = parseFloat(String(this.setValue).slice(0, 3));
  },
  mounted() {
    if (this.setColor === "red") {
      this.color = "#EA475E";
    } else if (this.setColor === "orange") {
      this.color = "#F37F4D";
    } else {
      this.color = "#1AC592";
    }
  },
  watch: {
    isDarkTheme: {
      async handler(newVal) {
        this.show = false;
        await this.$nextTick();
        this.show = true;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      color: "",
      show: true,
      valueThis: 0,
    };
  },
};
</script>

<style lang="scss">
.green {
  background: rgba(42, 202, 149, 0.1);
}
.orange {
  background: rgba(232, 93, 63, 0.19);
}
.red {
  background: rgba(234, 71, 94, 0.22);
}
.greenWbg {
  background: #e9faf4;
}
.orangeWbg {
  background: #fbe1db;
}
.redWbg {
  background: #fad7dc;
}
.line-cube {
  width: 136px;
  height: 121px;
  display: flex;
  margin: 7px;
  position: relative;

  .color {
    position: absolute;
    width: calc(100% - 3px);
    height: 100%;
    left: 3px;
    top: 0;
    animation-name: color;
    animation-duration: 1s;
  }
  .line {
    width: 3px;
    height: 100%;
  }
  .wrapp-data {
    width: calc(100% - 3px);
    position: relative; // for not color txt with color class

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 9px;
      line-height: 10px;
      text-align: left;
      margin-left: 11px;
      margin-bottom: 14px;
      margin-top: 16px;
    }
    .value {
      width: 130px !important;
      height: fit-content;
      font-style: normal;
      font-weight: 600;
      font-size: 39px;
      line-height: 39px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 10px;
      .animate {
        width: 100%;
      }
    }
    .txt {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 10px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.8);
      text-align: center;
    }
  }
}
.line-cube.modal {
  width: 272px; // 136px;
  height: 242px; //121px;

  .color {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation-name: color;
    animation-duration: 1s;
  }
  .line {
    width: 4px;
    height: 100%;
  }
  .wrapp-data {
    width: calc(100% - 4px);
    position: relative; // for not color txt with color class

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 18px;
      text-align: left;
      margin-left: 21px;
      margin-bottom: 33px;
      margin-top: 26px;
    }
    .value {
      // width: 130px !important;
      width: 100% !important;
      height: fit-content;
      font-style: normal;
      font-weight: 600;
      font-size: 78px;
      line-height: 79px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 10px;
      .animate {
        width: 100%;
      }
    }
    .txt {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffffc2;
      text-align: center;
    }
  }
}
// -----------
.line-cube.white {
  @import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Bad+Script&display=swap");

  font-family: "Archivo" !important;
  width: 210px; // 136px;
  height: 180px; //121px;

  .color {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    animation-name: color;
    animation-duration: 1s;
  }
  .line {
    width: 4px;
    height: 100%;
    position: relative;
  }
  .wrapp-data {
    width: calc(100% - 4px);
    position: relative; // for not color txt with color class

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 16px;
      text-align: left;
      margin-left: 11px;
      margin-bottom: 10px;
      margin-top: 26px;
    }
    .value {
      // width: 130px !important;
      width: 100% !important;
      height: fit-content;
      font-style: normal;
      font-weight: 700 !important;
      font-size: 54px;
      line-height: 69px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 4px;
      .animate {
        width: 100%;
      }
    }
    .txt {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffffc2;
      text-align: center;
    }
  }
}
@keyframes color {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
</style>
