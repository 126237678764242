<template>
  <div class="pdf-preview" id="getPdf">
    <div class="action">
      <button class="red curved" @click="$emit('close-modal')">
        <span>Zatvori pregled</span>
      </button>
      <button class="red curved" @click="$emit('print-preview')">
        <span>Odštampaj</span>
      </button>
    </div>
    <!-- <chart-page :is-dark-theme="false"></chart-page> -->
    <TestPdf :isDarkTheme="false" :animationDuration="100" id="testPdfPrint"/>
    <!-- <div class="preview-image">
      <iframe class="content" :src="imagePreview" />
    </div> -->
    
  </div>
</template>

<script>
export default {
  props: {
    imagePreview: String,
  },
  mounted() {
    if (!this.imagePreview) {
      console.error("No image preview");
    }
  },
};
</script>

<style lang="scss" scoped>
.pdf-preview {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  height: 100%;
  padding: 10px;
  overscroll-behavior: contain;

  .preview-image {
    width: 100%;
    height: 100%;

    .content {
      flex: 1;
      width: 100%;
      height: 100%;
      overscroll-behavior: contain;
    }
  }

  .action {
    display: flex;
    gap: 10px;

  & .red.curved{
    width: auto;
    padding: 0 20px;
    height: 44px;
    border-radius: 121px;
    color: white;
    background-color: #dc304f;
    transition: all 0.3s ease;
  } 

  }
}
#testPdfPrint{
  padding: 20px 1px 5px 1px;
}
</style>
