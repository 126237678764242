// import api from "../api";
import { createStore } from "vuex";

import { getField, updateField } from "vuex-map-fields";
export default createStore({
  state: {
    patientData: {
      firstName: "",
      lastName: "",
      gender: "",
      dateOfBirth: "",
      height: "",
      weight: "",
      bmi: "",
      bfp: "",
      bmr: "",
      bps: "",
      bpd: "",
      cholesterol: "",
      cholesterolHDL: "",
      cholesterolLDL: "",
      triglycerides: "",
      physicalActivity: "",
      muscleStrength: "",
      sleepDuration: "",
      sleepEfficiency: "",
      mentalState: "",
      depression: "",
      nervousness: "",
      stress: "",
      estimatedAgeMDNA: "",
      estimatedAgePtypic: "",
    },
    user: {
      username: "Doctor1",
      password: "Vizim257",
    },
  },
  mutations: {
    updateField,
    setPatientData(state, data) {
      state.patientData = data;
    },
    clearPatientData(state, data) {
      for (const key of Object.keys(state.patientData)) {
        state.patientData[key] = "";
      }
      sessionStorage.removeItem("patientDataStore");
    },
  },
  actions: {},
  modules: {},
  getters: {
    getField,
    patientData(state) {
      return state.patientData;
    },
    isValidPatientData(state) {
      return Object.values(state.patientData).every(
        (x) => x !== null && x !== ""
      );
    },
  },
});
