<template>
  <div
    :class="{ 'average-dream': true, modal: isModal }"
    :style="{
      color: isDarkTheme ? '#fff' : '#2B2B2E',
    }"
  >
    <div class="valueRef">
      <div class="hi"><p>9</p></div>
      <div class="m"><p>7</p></div>
      <div class="l"><p>0</p></div>
    </div>
    <div class="wrapp-chart-dr">
      <div
        :class="{
          pointer: isDarkTheme,
          pointerWbg: !isDarkTheme,
          noTransition: animationDuration <= 100,
        }"
        :style="{
          bottom:
            (!isModal && load) || (isModal && lodaM)
              ? calculate(averageDream.value) + '%'
              : 0,
        }"
      >
        <div class="pointerColor"></div>
        <div class="line"></div>
      </div>
      <div class="hi-value"></div>
      <div class="m-value"></div>
      <div class="l-value"></div>
    </div>
    <div class="wrapp-txt">
      <div class="value">
        <animateNum
          :animlength="animationDuration"
          :end="averageDreamThis"
          :start="0"
        />
        h
      </div>
      <div class="txt" :style="{ color: isDarkTheme ? '#ffffff' : '#7A7E89' }">
        {{ averageDream.txt }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AverageDream",
  props: {
    averageDream: {},
    isDarkTheme: {},
    animationDuration: {},
    isModal: {
      default: false,
    },
  },
  data() {
    return {
      load: false,
      lodaM: false,
      averageDreamThis: 0,
    };
  },
  created() {
    this.averageDreamThis = parseFloat(
      String(this.averageDream.value).slice(0, 2)
    );
  },
  mounted() {
    this.load = true;
    setTimeout(() => {
      this.lodaM = true;
    }, 200);
  },
  methods: {
    calculate(score) {
      let res = (score / 10) * 100;
      // if (res > 100) return 100;
      if (res > 97.5) return 97.5;
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.average-dream {
  font-family: "Archivo";
  color: #ffffff;
  // top: 218px;
  // width: 50%;
  padding-left: 30px;
  display: flex;
  min-height: fit-content;
  align-items: center;
  .valueRef {
    width: 16px;
    height: 180px;
    div {
      display: flex;
      align-items: flex-end;
      position: relative;
      p {
        position: absolute;
        bottom: -10px;
      }
    }
    div:last-of-type {
      display: flex;
      align-items: flex-end;
      position: relative;

      p {
        position: absolute;
        bottom: 0px;
      }
    }
    .hi {
      height: calc(10% - 2px);
    }
    .m {
      height: 20%;
    }
    .l {
      height: calc(70% - 5px);
    }
  }
  .wrapp-chart-dr {
    height: 180px;
    width: 72px !important;
    // background: chartreuse;
    position: relative;

    .pointer {
      position: absolute;
      width: 75px;
      height: 7px;
      left: 0px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition-duration: 2s;

      .pointerColor {
        position: absolute;
        width: 72px;
        height: 7px;
        left: -0px;
        top: 0;

        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0.657) 53.12%,
          rgba(255, 255, 255, 0.4) 98.96%
        );
        filter: blur(2px);
      }
      .line {
        height: 0px;
        width: 60%;
        border-top: 1px solid rgba(255, 255, 255, 0.507);
      }
    }
    .pointerWbg {
      position: absolute;
      width: 65px;
      height: 3px;
      left: 3px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition-duration: 2s;
      border-radius: 10px;
      overflow: hidden;

      .pointerColor {
        position: absolute;
        width: 65px;
        height: 3px;
        left: -0px;
        top: 0;
        // background: #dadada;
        // background: linear-gradient(
        //   90deg,
        //   rgba(255, 255, 255, 0.4) 0%,
        //   rgba(255, 255, 255, 0.657) 53.12%,
        //   rgba(255, 255, 255, 0.4) 98.96%
        // );
        // filter: blur(2px);
      }
      .line {
        height: 0px;
        width: 90%;
        border-top: 2px solid #dadada;
        position: absolute;
        top: -1px;
      }
    }
    .noTransition {
      transition-duration: 0s;
    }
    .hi-value {
      // height: calc(10%);
      height: calc(10% - 2px);
      background: #f4667a;
    }
    .m-value {
      // height: calc(20% - 2px);
      height: 20%;
      margin-top: 1px;
      background: #27c994;
    }
    .l-value {
      height: calc(70% - 5px);
      margin-top: 1px;
      background: #ea475e;
    }
  }
  .wrapp-txt {
    margin-left: 16px;
    margin-top: auto;
    margin-bottom: 5px;
    .value {
      font-weight: 600;
      font-size: 27px;
      line-height: 29px;
      margin-bottom: 6px;
    }
    .txt {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 12px;
      /* identical to box height */

      text-align: center;
    }
  }
}
.average-dream.modal {
  width: 70%;
  padding-left: 46px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .wrapp-chart {
    height: 350px;
    width: 122px;
    // background: chartreuse;
    position: relative;

    .pointer {
      position: absolute;
      width: 125px;
      height: 7px;
      left: 0px;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition-duration: 2s;

      .pointerColor {
        position: absolute;
        width: 122px;
        height: 7px;
        left: -0px;
        top: 0;

        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0.657) 53.12%,
          rgba(255, 255, 255, 0.4) 98.96%
        );
        filter: blur(2px);
      }
      .line {
        height: 0px;
        width: 60%;
        border-top: 1px solid rgba(255, 255, 255, 0.507);
      }
    }

    // .hi-value {
    //   height: calc(10%);

    //   background: #f4667a;
    // }
    // .m-value {
    //   height: calc(20% - 5px);
    //   margin-top: 5px;
    //   background: #27c994;
    // }
    // .l-value {
    //   height: calc(70% - 5px);
    //   margin-top: 5px;
    //   background: #ea475e;
    // }
  }
  .wrapp-txt {
    margin-left: 26px;

    .value {
      font-weight: 600;
      font-size: 37px;
      line-height: 39px;
      margin-bottom: 10px;
    }
    .txt {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 12px;
      /* identical to box height */

      text-align: center;
    }
  }
}
</style>
