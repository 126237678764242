<template>
  <div :class="['line-charts', { modal: isModal }]">
    <div
      class="wrapp-title"
      :style="{ color: this.isDarkTheme ? '#fff' : '#2B2B2E' }"
    >
      <div class="title">{{ value.name }}</div>
      <div class="value">{{ valueThis }} mmol/l</div>
    </div>
    <div
      class="chart"
      :style="{ background: isDarkTheme ? '#3b3e45' : '#EBEBEB' }"
    >
      <div
        :class="{
          fill: true,
          fillBig: fillBig && isDarkTheme,
          fillSm: fillSm && isDarkTheme,
          fillMed: fillMed && isDarkTheme,
          fillNoTransition: animationDuration <= 100,
          fillBad: fillBad && isDarkTheme,
          fillBigBad: fillBigBad && isDarkTheme,
          fillSmBad: fillSmBad && isDarkTheme,
          fillMedBad: fillMedBad && isDarkTheme,
          fillRed: fillRed && !isDarkTheme,
          fillGreen: fillGreen && !isDarkTheme,
        }"
        :style="{
          width:
            (!isModal && load) || (isModal && loadM)
              ? calculate(value.value, value.recommended) >= 100
                ? 100 + '%'
                : calculate(value.value, value.recommended) + '%'
              : 0,
          'border-radius':
            calculate(value.value, value.recommended) >= 100
              ? '10px 10px 10px 10px'
              : '10px 0 0 10px',
        }"
      ></div>
    </div>
    <div
      class="recommended"
      :style="{ color: isDarkTheme ? '#fff' : '#2B2B2E' }"
    >
      <div
        class="line"
        :style="{
          background: isDarkTheme ? '#fff' : '#000',
        }"
      ></div>
      {{ value.recommended }} mmol/l
    </div>
  </div>
</template>

<script>
export default {
  name: "line-charts",
  props: ["value", "isDarkTheme", "animationDuration", "isModal", "loadP"],
  data() {
    return {
      load: false,
      loadM: false,
      fillBig: false,
      fillSm: false,
      fillMed: false,
      fillBad: false,
      fillBigBad: false,
      fillSmBad: false,
      fillMedBad: false,
      fillGreen: false,
      fillRed: false,
      valueThis: 0,
    };
  },
  created() {
    this.valueThis = parseFloat(String(this.value.value).slice(0, 4));
  },
  mounted() {
    setTimeout(() => {
      this.load = true;
      this.loadM = true;
      this.fillBad = this.value.name == "HDL holesterol";
      this.calculateColor(this.valueThis, this.value.recommended);
      this.calculateColorBright(this.valueThis, this.value.recommended);
    }, 250);
  },
  methods: {
    calculate(score, avg) {
      let help = avg * 2;
      return (score / help) * 100;
    },
    calculateColor(score, avg) {
      let help = (score / avg) * 100;
      // console.log("100 - " + help + "=" + (100 - help));
      // console.log("+" + this.value.name + "+");
      // console.log(this.value.name.startsWith("HDL"));
      if (100 - help > 20) {
        if (this.value.name.startsWith("HDL")) {
          this.fillSmBad = true;
        } else this.fillSm = true;
      } else if (100 - help < -30 && 100 - help > -55) {
        if (this.value.name.startsWith("HDL")) {
          this.fillMedBad = true;
        } else this.fillMed = true;
        // this.fillMed = true;
      } else if (100 - help < -60) {
        if (this.value.name.startsWith("HDL")) {
          this.fillBigBad = true;
        } else this.fillBig = true;
        // this.fillBig = true;
      }
    },
    calculateColorBright(score, avg) {
      let help = (score / (avg * 2)) * 100;

      if (help <= 50) {
        if (this.value.name.startsWith("HDL")) {
          this.fillRed = true;
        } else {
          this.fillGreen = true;
        }
      } else {
        if (this.value.name.startsWith("HDL")) {
          this.fillGreen = true;
        } else this.fillRed = true;
      }
    },
  },
};
</script>

<style lang="scss">
.line-charts {
  color: #ffffff;
  .wrapp-title {
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
    color: #fff;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      /* identical to box height */

      text-align: center;
    }
    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
    }
  }
  .chart {
    padding: 3px;

    position: relative;
    width: 397.52px;
    height: 17.78px;
    border-radius: 10px;
    background: #3b3e45;
    overflow: hidden;
    .fill {
      border-radius: 10px 0 0 10px;
      height: 100%;
      background-image: linear-gradient(90deg, #28ca90 0%, #e8444a 167.64%);
      width: 0;
      max-width: 100%;
      transition-duration: 2s;
    }
    .fillBig {
      background-image: linear-gradient(90deg, #33c78c 33.94%, #e9454a 61.05%);
    }
    .fillSm {
      // background: linear-gradient(90deg, #e8444a 0%, #28ca90 394.24%);
      background: #28ca90;
    }
    .fillMed {
      background-image: linear-gradient(90deg, #28ca91 -3.93%, #e8444a 116.17%);
    }
    .fillNoTransition {
      transition-duration: 0s;
    }
    .fillBad {
      background: #e9454a;
      background: linear-gradient(90deg, #e9454a 0%, #28ca90 167.64%);
    }
    .fillBigBad {
      background: linear-gradient(90deg, #e9454a 33.94%, #33c78c 61.05%);
    }
    .fillSmBad {
      background: linear-gradient(90deg, #e8444a 0%, #28ca90 394.24%);
      // background: #e9454a;
    }
    .fillMedBad {
      background: linear-gradient(90deg, #e9454a -3.93%, #33c78c 116.17%);
    }
    .fillGreen {
      background: #33c78c;
    }
    .fillRed {
      background: #e9454a;
    }
  }
  .recommended {
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 3px;
    margin-top: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 13px;
    text-align: center;

    color: #ffffff;

    .line {
      position: absolute;
      right: calc(50% - 1px);
      left: calc(50% - 1px);
      top: -2px;
      // border-top: 1px solid #fff;
      height: 1px;
      background-color: #fff;
      width: 5px;
      transform: rotate(90deg);
    }
  }
}
.line-charts.modal {
  color: #ffffff;
  .wrapp-title {
    display: flex;
    justify-content: space-between;
    padding: 0 3px;
    color: #fff;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      /* identical to box height */

      text-align: center;
    }
    .value {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
  }
  .chart {
    padding: 4px;

    position: relative;
    width: 597.52px;
    height: 27.78px;
    border-radius: 20px;
    background: #3b3e45;
    overflow: hidden;

    .fill {
      border-radius: 20px 0 0 20px;
      height: 100%;
      background: linear-gradient(90deg, #28ca90 0%, #e8444a 167.64%);
      width: 0;
      max-width: 100%;
      transition-duration: 2s;
    }
    .fillBig {
      background: linear-gradient(90deg, #33c78c 33.94%, #e9454a 61.05%);
    }
    .fillSm {
      // background: linear-gradient(90deg, #e8444a 0%, #28ca90 394.24%);
      background: #28ca90;
    }
    .fillMed {
      background: linear-gradient(90deg, #28ca91 -3.93%, #e8444a 116.17%);
    }
    .fillNoTransition {
      transition-duration: 0s;
    }
    .fillBad {
      background: #e9454a;
      background: linear-gradient(90deg, #e9454a 0%, #28ca90 167.64%);
    }
    .fillBigBad {
      background: linear-gradient(90deg, #e9454a 33.94%, #33c78c 61.05%);
    }
    .fillSmBad {
      background: linear-gradient(90deg, #e8444a 0%, #28ca90 394.24%);
      // background: #e9454a;
    }
    .fillMedBad {
      background: linear-gradient(90deg, #e9454a -3.93%, #33c78c 116.17%);
    }
    .fillGreen {
      background: #33c78c;
    }
    .fillRed {
      background: #e9454a;
    }
  }
  .recommended {
    display: flex;
    justify-content: center;
    position: relative;
    margin-right: 3px;
    margin-top: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;

    color: #ffffff;

    .line {
      position: absolute;
      right: calc(50% - 1px);
      left: calc(50% - 2px);
      top: 2px;
      // border-top: 1px solid #fff;
      height: 2px;
      background-color: #fff;
      width: 7px;
      transform: rotate(90deg);
    }
  }
}
</style>
